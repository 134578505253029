let BASE_URL = "/myStarsBoBFF";
let DATALOADER = "";
//let BASE_URL = "http://10.128.250.142/myStarsBoBFF";
//let DATALOADER = "http://10.128.250.142";
// let OAUTH_BASE_URL = "https://10.28.250.37/myStars";

export const OPENED_CLUSTER_LIMIT = 30;

export const OAUTH_AUTHENTICATION = BASE_URL + "/authorization/login";
export const OAUTH_CLIENTID = "ZWFzeUFzc29ydG1lbnQ6ZWFzeUFzc29ydG1lbnRfcHdk";

export const FRONT_OFFICE_URL = "/myStars";
export const LOGOUT_URL = "/myStars/logout";

/*** AUTH ***/
export const GET_AUTHORIZATION_GRANT = BASE_URL + "/authorization/grant";

/*** MODULE ***/
// export const GET_ALL_FILTERS_MODULE = BASE_URL + "/module/allFilters";
export const GET_ALL_FILTERS_MODULE = BASE_URL + "/module/exportFilters";

export const GET_MODULELIST_URL = BASE_URL + "/module/filtered";
export const GET_MACROLIST_URL = BASE_URL + "/module/macroFamily";
export const GET_FILTERS_MODULE = BASE_URL + "/module/filters";
export const IMPORT_MODULE_URL = BASE_URL + "/module/importModules";
export const SAVE_MODULE_URL = BASE_URL + "/module/save";
//NEW 14/02
// export const SAVE_MODULES_URL = BASE_URL + "/module/saveModules";
export const SAVE_MODULES_URL = BASE_URL + "/module/saveList";
export const PUBLISH_MODULES_URL = BASE_URL + "/module/publishList";
//
export const PUBLISH_MODULE_URL = BASE_URL + "/module/publish";
export const GET_MODULES_BY_PRODUCT = BASE_URL + "/module/productModule";
export const GET_WORKING_MODULE = BASE_URL + "/module/modules";
export const GET_MODULES_FILTERED = BASE_URL + "/module/filteredDetails";
export const GET_MODULES_FILTERED_PTY = BASE_URL + "/module/filteredDetailsPty";
export const GET_PERC_OF_REFRESH = BASE_URL + "/module/percentage";
export const GET_NEWNESS_LEGO_URL = BASE_URL + "/module/filteredDetailsLego";
export const GET_NEWNESS_NOT_LEGO_URL =
   BASE_URL + "/module/filteredDetailsNotLego";
export const GET_PENDING_MODULE_INFO = BASE_URL + "/module/pendingModuleInfo";
export const GET_PDFAREA_INFO = BASE_URL + "/pdfFilesArea/pdfExportStatus";


/*** CLUSTER */
export const GET_INITIALFILTERS_URL = BASE_URL + "/cluster/initialFilters";
export const GET_CLUSTER_FILTERS_OPTIONS = BASE_URL + "/cluster/filters";
export const GET_PENDING_AREA_INITIAL_FILTERS = BASE_URL + "/cluster/pendingAreaInitialFilters";
export const GET_PENDING_CLUSTER_INFO = BASE_URL + "/cluster/pendingClusterInfo";

// export const GET_ALL_FILTERS_CLUSTER = BASE_URL + "/cluster/allFilters";
export const GET_ALL_FILTERS_CLUSTER = BASE_URL + "/cluster/exportFilters";

export const GET_CLUSTERLIST_URL = BASE_URL + "/cluster/filtered";
export const GET_CLUSTER = BASE_URL + "/cluster/clusterDetails";
export const SAVE_CLUSTER = BASE_URL + "/cluster/save";
export const PUBLISH_CLUSTER = BASE_URL + "/cluster/publish";
export const CHECK_PUBLISH_CLUSTER = BASE_URL + "/cluster/checkForPublish";
export const GET_PERC_OF_REFRESH_SINGLE_CLUSTER =
   BASE_URL + "/cluster/clusterPercentage";
export const GET_RANGE_FACING_CLUSTER = BASE_URL + "/cluster/rangeFacingDto";
export const ACCEPT_CLUSTER = BASE_URL + "/cluster/accept";
export const REJECT_CLUSTER = BASE_URL + "/cluster/reject";
export const GET_LIST_SUGGESTED_CLUSTER = BASE_URL + "/cluster/suggestList";
export const GET_CLUSTER_NAME = BASE_URL + "/cluster/getGenericName";
export const GET_ALL_LIA = BASE_URL + "/lia/all"; // LIA

/* CLUSTER STATISTICS */
export const GET_CLUSTER_BY_STATS = BASE_URL + "/cluster/statistic";
export const GET_STATS_FILTERS = BASE_URL + "/cluster/statisticFilters";
export const GET_STATS_FILTERS_BY_BRAND =
   BASE_URL + "/cluster/statisticFiltersByBrand";

/** LOCK */
export const LOCK_MODULE_URL = BASE_URL + "/module/lock";
export const LOCK_CLUSTER_URL = BASE_URL + "/cluster/lock";
export const LOCK_MACRO_URL = BASE_URL + "/module/macroFamily/lock";

export const UNLOCK_MODULE_URL = BASE_URL + "/module/unlock";
export const UNLOCK_CLUSTER_URL = BASE_URL + "/cluster/unlock";
export const UNLOCK_MACRO_URL = BASE_URL + "/module/macroFamily/unlock";

/** PRODUCT **/
export const GET_PRODUCT_FILTERED = BASE_URL + "/product/filtered";
export const GET_ACTIVE_FILTERS_URL = BASE_URL + "/product/filters";
export const GET_IBIC_FILTERS_URL = BASE_URL + "/product/ibic/filters";

/*UPLOAD FILES */
export const IMPORT_FILE_URL_3_FILE = "/myStarsData/fileLoader/importXlsx2";
export const IMPORT_FILE_URL_4_FILE = "/myStarsData/fileLoader/importXlsx";
export const IMPORT_STATUS_URL = "/myStarsData/fileLoader/uploadStatus";

/** USERS */
export const CREATE_USER_CREATOR_URL = BASE_URL + "/user/insertCreator";
export const GET_USERS_LIST_URL = BASE_URL + "/user/allCreators";
//export const GET_COUNTRYLIST_URL = BASE_URL + "/store/starsZone";
  export const GET_COUNTRYLIST_URL = BASE_URL + "/starsZone";


export const GET_MANAGE_BRANDLIST_URL = BASE_URL + "/brand/all";
export const GET_RULE_LIST_URL = BASE_URL + "/rule/all";
export const ENABLE_DISABLE_USER = BASE_URL + "/user/";
export const SAVE_USER = BASE_URL + "/user/save";

/** EXPORT FILES */

export const GET_MODULES_EXPORT_XLS = DATALOADER + "/myStarsData/export/xls/module";
export const GET_MODULES_EXPORT_XLS_PHOTO = DATALOADER + "/myStarsData/export/xls/module";
export const GET_MODULES_EXPORT_PDF = DATALOADER + "/myStarsData/export/pdf/module";
export const GET_CLUSTER_EXPORT_XLS = DATALOADER +"/myStarsData/export/xls/cluster";
export const GET_CLUSTER_EXPORT_XLS_PHOTO =DATALOADER + "/myStarsData/export/xls/cluster";
export const GET_CLUSTER_EXPORT_PDF =DATALOADER + "/myStarsData/export/pdf/cluster";
export const GET_MODULES_MASSIVE_EXPORT_PDF =DATALOADER + "/myStarsData/export/pdf/moduleMassive";
export const GET_CLUSTER_MASSIVE_EXPORT_PDF =DATALOADER + "/myStarsData/export/pdf/clusterMassive";
export const GET_TEMPLATE_EXPORT = BASE_URL + "/templates/getByTemplatesType";
export const GET_MODULELIST_DRY_URL = BASE_URL + "/module/filteredDry";
export const GET_CLUSTERLIST_DRY_URL = BASE_URL + "/cluster/filteredDry";

/* NOT USED */
export const GET_RELEASE_URL = BASE_URL + "/release/all";
export const GET_BRANDLIST_URL = BASE_URL + "/cluster/brand";

export const GET_PLANNINGLIST_URL = BASE_URL + "/cluster/planningChannel";
export const GET_MODULE_LIST_NEWNESS =
   BASE_URL + "/module/newnessProductModule";
export const GET_LIST_MODULES_BY_PROD =
   BASE_URL + "/module/publishedProductModule";

/* NOT USED RELEASE */
export const CREATE_RELEASE = BASE_URL + "/release/createNew";
export const OPEN_CLOSE_RELEASE = BASE_URL + "/release/changeStatus";
export const PUBLISH_FO_RELEASE = BASE_URL + "/release/publish";
export const PUBLISH_VIEWER_RELEASE = BASE_URL + "/release/publishViewer";
export const REMOVE_RELEASE = BASE_URL + "/release/remove";

export const MODULE_COPY_COMPOSITION = BASE_URL + "/module/copyCompositions";
export const CLUSTER_COPY_COMPOSITION = BASE_URL + "/cluster/copyCompositions";
export const SKU_DISCO_INFO_FILTERS = BASE_URL + "/sku/skuDiscoInfoFilters";
export const SKU_DELETED_INFO_FILTERS = BASE_URL + "/sku/skuDeletedInfoFilters";
export const SKU_DISCO_INFO = BASE_URL + "/sku/skuDiscoInfo";
export const SKU_DELETED_INFO = BASE_URL + "/sku/skuDeletedInfo";
export const MODULE_EXTRA_SELL_IN = BASE_URL + "/module/extraSellIn";
export const MODULE_PERFORMANCES = BASE_URL + "/module/modulePerformances";
export const UPLOAD_GET_STATUS = BASE_URL + "/uploadStatus/getUploadStatus";
export const UPLOAD_STATUS = BASE_URL + "/uploadStatus/";
export const MODULE_MASSIVE_EDIT = BASE_URL + "/module/massiveEdit/";
export const CLUSTER_MASSIVE_EDIT = BASE_URL + "/cluster/massiveEdit/";
export const CLASSIFICATION_MASSIVE_EDIT = BASE_URL + "/cluster/massiveEdit/classification";
export const CLUSTER_MASSIVE_CREATION = BASE_URL + "/cluster/massiveCreation/";
export const MODULE_MASSIVE_CREATION = BASE_URL + "/module/massiveCreation/";
export const SKU_DISCO_ALERT = BASE_URL + "/sku/skuDiscoAlert/";
export const CLUSTER_EXISTS = BASE_URL + "/cluster/checkIfExists/";
export const MODULE_EXISTS = BASE_URL + "/module/checkIfExists/";

export const GET_PENDING_AREA_ALERTS_LENGTH = BASE_URL + "/module/countPendingAreaAlerts";
/* NOT USED RELEASE */
export const SKU_DELETE_INFO = BASE_URL + "/sku/skuDeletedInfo";

/* CLASSIFICATION */
export const CLIENT_CLASSIFICATION_CREATE = BASE_URL + "/classification/create"
export const CLIENT_CLASSIFICATION_ALL = BASE_URL + "/classification/all"
export const CLIENT_CLASSIFICATION_DELETE = BASE_URL + "/classification/delete"

/* MANAGE BRANDS */
export const GET_BRANDS_WITH_USERS = BASE_URL + "/brand/allBrandsWithUsers";
export const GET_ALL_USERS = BASE_URL + "/user/allNoGrants";
export const SAVE_BRANDS = BASE_URL + "/brand/save";

export const IMAGE_BASE_URL = "https://myluxottica-im.luxottica.com";