import { useEffect, useState } from "react";

import Button from "../UI-components/Button";
import { isFunction } from "lodash";

const emptyRowModel = {
   cluster: [""],
   addClassification: [""],
   removeClassification: [""]
};

const initialState = {
   cluster: [""],
   addClassification: [""],
   removeClassification: [""]
};

const MassiveEditClusterWITable = ({ onEditRow, resetTableData, setCanPublish }) => {
   const [inputRows, setInputRows] = useState([initialState]);

   useEffect(() => {
      setInputRows([
         {
            cluster: [""],
            addClassification: [""],
            removeClassification: [""]
         },
      ]);
   }, []);

   useEffect(() => {
      if (resetTableData) {
         setInputRows([
            {
               cluster: [""],
               addClassification: [""],
               removeClassification: [""]
            },
         ]);
      }
   }, [resetTableData]);

   const checkIsEmpty = () => {
      const isEmpty =
         inputRows.length >= 1 &&
         JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowModel);
      return isEmpty;
   };

   const checkIfModuleTable = () => {
      return false;
   };

   const handleChange = (selectType, optionsSelected, index) => {
      if (isFunction(setCanPublish)) setCanPublish(true);
      const mapped = inputRows.map((row, rowIndex) => {
         if (rowIndex === index) {
            row[selectType] = [optionsSelected];
         }
         return row;
      });
      setInputRows(mapped);
   };

   const addRow = () => {
      setInputRows((prevState) => [
         {
            cluster: [""],
            addClassification: [""],
            removeClassification: [""]
         },
         ...prevState,
      ]);
   };

   const removeRow = (index) => {
      setInputRows((prevState) => {
         const x = prevState.filter(
            (p, i) => prevState.length === 1 || i !== index
         );

         if (x.length === 1 && prevState.length === 1) {
            return [
               {
                  cluster: [""],
                  addClassification: [""],
                  removeClassification: [""]
               },
            ];
         }
         return x;
      });
   };

   useEffect(() => {
      if (isFunction(onEditRow))
         onEditRow(
            inputRows,
            checkIsEmpty(),
            checkIfModuleTable(),
            !disableCtasCluster()
         );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputRows]);

   const disableCtasCluster = () => {
      const mappedClusterAndClassificationFromRows =
         inputRows &&
         inputRows.map((row) => {
            const clusterNotEmpty = row.cluster.includes("");

            const checkAlmostOneNotEmpty =
               row.addClassification.includes("") &&
               row.removeClassification.includes("");

            return {
               clusterNotEmpty,
               checkAlmostOneNotEmpty,
            };
         });

      const result = mappedClusterAndClassificationFromRows.every((value) => {
         return !value.checkAlmostOneNotEmpty && !value.clusterNotEmpty;
      });

      return result;
   };

   const MassiveEditWITableGeneratedRows = () => {
      return inputRows.map((row, i) => {
         return (
            <tr key={i}>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your cluster..."
                     onChange={(event) =>
                        handleChange("cluster", event.target.value, i)
                     }
                     value={row.cluster}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your Client..."
                     onChange={(event) =>
                        handleChange("addClassification", event.target.value, i)
                     }
                     value={row.addClassification}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your Client..."
                     onChange={(event) =>
                        handleChange("removeClassification", event.target.value, i)
                     }
                     value={row.removeClassification}
                  />
               </td>
               <td>
                  {i === 0 ? (
                     <div style={{ display: "flex" }}>
                        <Button handleClick={addRow}>+</Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  ) : (
                     <div style={{ display: "flex" }}>
                        <Button btnClass="hideBtn" handleClick={() => {}}>
                           +
                        </Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  )}
               </td>
            </tr>
         );
      });
   };

   return (
      <table className="massive-edit-module-table">
         <thead>
            <tr>
               <th>CLUSTER</th>
               <th>ADD CLIENT CLASS.</th>
               <th>REMOVE CLIENT CLASS.</th>
            </tr>
         </thead>
         <tbody>{MassiveEditWITableGeneratedRows()}</tbody>
      </table>
   );
};

export default MassiveEditClusterWITable;
