/* eslint-disable array-callback-return */

import * as _ from "lodash";
import * as actions from "../../store/actions";
import * as urlList from "../../config";

import React, {Component} from "react";
import {
    canReopen,
    getArrayFromObj,
    isVisibleForUser,
    onUnload,
    orderModules,
    orderModulesByMacro,
    sortMacro,
} from "../../services/Api";

import Header from "../UI-components/Header";
import LiaList from "../Cluster-Composition/Cluster-Lia/LiaList";
import Loader from "../UI-components/Loader";
import ModuleListAreaContainer from "./ModuleListAreaContainer";
import Popup from "../UI-components/Popup";
import SearchModule from "./SearchModule";
import {connect} from "react-redux";
import {getCookie} from "../../services/cookie";
import {cloneDeep, uniqBy} from "lodash"
import ModuleAll from "./ModuleAll";


class ClusterComposition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            action: null,
            counterWip: 0,
            cluster: null,
            clusterList: null,
            modulesChanged: [],
            modulePrincList: [],
            moduleRemovedList: [],
            commonModules: [],
            removedCommonModules: [],
            isClusterChanged: false,
            dragFrom: null,
            showPopup: false,
            isLiaListEdited: false,
            isClassificationEdited: false,

            isPopupExitOpen: false,
            isPopupChangeOpen: false,
            isPopupSavedOpen: false,
            isPopupSaveNewDescriptionOpen: false,
            isPopupWipOpen: false,
            isPopupWantPublishOpen: false,
            isPopupClusterTwin: false,
            isPopupUnlockedClusterOpen: false,

            draggedModule: null,
            selectedCluster: null,
            moduleClicked: null,

            isModuleSelected: false,
            clustersIdentical: [],
            newTabHeader: null,

            visibleClusterIndex: 0,
            descriptions: [
                {
                    clusterId:null,
                    value: "",
                    isChanged: false,
                    editMode: false
                }
            ]
        };

        this.handleSaveClick = this.handleSaveClick.bind(this);
    }

    //check if cluster is locked
    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const clusterIdQuery = query.get("clusterId");
        const monthQuery = query.get("monthId");

        if (clusterIdQuery && monthQuery) {
            this.props.getInitialFiltersList();
            let params = {
                clusterId: clusterIdQuery,
                monthId: monthQuery,
            };
            let url = urlList.GET_CLUSTER;
            fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data && data !== null && data !== undefined) {
                        if (!data) {
                            return this.props.history.push("/");
                        }
                        const selectedFilters = {
                            monthId: [],
                            planningChannel: [],
                            brand: [],
                            collection: [],
                            country: [],
                        };

                        selectedFilters.monthId.push(data.monthId);
                        selectedFilters.brand.push(data.brandCode);
                        this.props.getFilteredList("clusters", selectedFilters);
                        this.props.getFilteredList("macroFamily", selectedFilters);
                        this.props.getModuleFilters(selectedFilters, true)
                        this.setState({
                            newTabHeader: {
                                brand: data.brandCode,
                                monthId: data.monthId,
                            },
                        });
                        const callId = Math.random();
                        this.props.getClusterListSuccess(data, callId);
                        let viewer = (data.locked && data.userLock && this.props.username.toLowerCase() !== data.userLock.toLowerCase()) ? true : false;
                        this.props.getClusterWithLock(data, null, viewer);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        if (!this.props.results && !(clusterIdQuery && monthQuery)) {
            const selectedFilters = {
                monthId: [],
                planningChannel: [],
                brand: [],
                collection: [],
                country: [],
            };

            selectedFilters.monthId.push(this.props.selectedFilters.months.value);

            selectedFilters.brand.push(this.props.selectedFilters.brand.value);

            if (
                this.props.selectedFilters.planningChannel &&
                this.props.selectedFilters.planningChannel.constructor === Object
            )
                selectedFilters.planningChannel.push(
                    this.props.selectedFilters.planningChannel.value
                );

            this.props.selectedFilters.collection.map((collection) => {
                selectedFilters.collection.push(collection.value);
            });
            this.props.selectedFilters.countryList.map((country) => {
                selectedFilters.country.push(country.value);
            });

            this.props.getFilteredList("clusters", selectedFilters);
            this.props.getFilteredList("macroFamily", selectedFilters);
        }

        if (!this.props.isGAJustInizialized && (!clusterIdQuery || !monthQuery))
            this.props.ReactGA.pageview(
                window.location.pathname + window.location.search
            );

        if (this.props.isNewCluster) {
            this.setState({
                clusterList: this.props.clusterList,
                descriptions: [
                    {
                        clusterId:this.props.clusterList[0].clusterId,
                        value: this.props.clusterList[0].clusterDescription,
                        isChanged: false,
                        editMode: false
                    }
                ]
            });
        }

        if (process.env.NODE_ENV !== "development") {
            window.addEventListener("beforeunload", (e) =>
                onUnload(
                    e,
                    this.state.isClusterChanged && !this.state.isModuleSelected
                )
            );
        }
        if (!clusterIdQuery || !monthQuery) {
            let unblock = this.props.history.block((location, action) => {
                if (this.state.isClusterChanged && !this.state.isModuleSelected)
                    return "You are leaving the page without saving. Are you sure you want to continue?";
            });

            this.setState({
                unblock: unblock,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.clusterList !== this.props.clusterList) {
            //divide modules
            let modulePrincList = [],
                moduleRemovedList = [];

            const numOfModules = this.props.clusterList.map(cluster => cluster.modules.length).reduce((a, b) => a + b)
            if (numOfModules > 0) {

                // single cluster version
                /*
                this.props.cluster.modules.map((moduleItem) => {
                   if (moduleItem.clusterCompositionStatus !== "REMOVED") {
                      modulePrincList.push(moduleItem);
                   } else {
                      moduleRemovedList.push(moduleItem);
                   }
                });
                */

                // clusterList version
                this.props.clusterList.forEach((cluster) => {
                    cluster.modules.forEach(module => {
                        const moduleClusterId = {
                            ...module,
                            clusterId: cluster.clusterId
                        }
                        if (moduleClusterId.clusterCompositionStatus !== "REMOVED") {
                            modulePrincList.push(moduleClusterId);
                        } else {
                            moduleRemovedList.push(moduleClusterId);
                        }
                    })
                })

                modulePrincList = orderModulesByMacro(modulePrincList);
                this.getPercOfRefreshClusters(
                    Object.keys(modulePrincList).length
                        ? modulePrincList
                        : this.state.modulePrincList
                );
                this.getRangeFacingClusters(
                    Object.keys(modulePrincList).length
                        ? modulePrincList
                        : this.state.modulePrincList
                );
            }
            let descriptions = []
            if (this.props.isNewCluster) {
                descriptions = [{
                    clusterId: this.props.cluster.clusterId,
                    value: this.props.cluster.clusterDescription,
                    isChanged: false,
                    editMode: false
                }]
            } else {
                descriptions = this.props.clusterList.map(c => {
                    const oldDescription = this.state.descriptions.find(d => d.clusterId === c.clusterId);
                    if (oldDescription){
                        return cloneDeep(oldDescription)
                    }
                    return {
                        clusterId: c.clusterId,
                        value: c.clusterDescription,
                        isChanged: false,
                        editMode: false
                    }
                })
            }
            this.setState({
                cluster: this.props.cluster,
                descriptions,
                clusterList: this.props.clusterList,
                commonModules: orderModulesByMacro(this.initCommonModules(modulePrincList, this.props.clusterList)),
                modulePrincList: Object.keys(modulePrincList).length
                    ? modulePrincList
                    : this.state.modulePrincList,
                moduleRemovedList: Object.keys(modulePrincList).length
                    ? moduleRemovedList
                    : this.state.moduleRemovedList,
            });
        }

        if (
            prevProps.isActionSuccessful !== this.props.isActionSuccessful &&
            this.props.isActionSuccessful === true
        ) {
            this.togglePopup("isPopupSavedOpen");
            const descriptions = cloneDeep(this.state.descriptions);
            if (descriptions){
                descriptions.forEach(d => {
                    d.isChanged = false;
                    d.editMode = false;
                })
                this.setState({
                    descriptions
                })
            }
        } else if (
            prevProps.isLoadingSave !== this.props.isLoadingSave &&
            !this.props.isLoadingSave &&
            this.props.errorSave
        ) {
            this.togglePopup("isPopupUnlockedClusterOpen");
        }

        if (
            prevProps.isCheckingPublish !== this.props.isCheckingPublish &&
            !this.props.isCheckingPublish &&
            this.props.errorCheckPublish
        ) {
            this.setState({
                isPopupUnlockedClusterOpen: true,
                isPopupWantPublishOpen: false,
            });
        }

        // single cluster

        /*
        if (prevProps.percRefresh !== this.props.percRefresh) {
           this.setState({
              percRefresh: this.props.percRefresh,
           });
        }
        */

        // cluster list
        if (prevProps.percRefreshList !== this.props.percRefreshList) {
            this.setState({
                percRefreshList: this.props.percRefreshList,
            });
        }

        if (prevProps.rangeFacingList !== this.props.rangeFacingList) {
            this.setState({
                rangeFacingList: this.props.rangeFacingList,
            });
        }

        /*if (!this.props.lockClusterStatus && !this.props.isNewCluster && (!clusterIdQuery || !monthQuery)) {
           return this.props.history.push("/");
        }*/

        if (
            prevProps.lockModule !== this.props.lockModule &&
            this.props.lockModule
        ) {
            this.setState({isModuleSelected: true});
        }
    }

    componentWillUnmount() {
        const query = new URLSearchParams(window.location.search);
        const clusterIdQuery = query.get("clusterId");
        const monthQuery = query.get("monthId");
        if (!(clusterIdQuery && monthQuery) && this.state.unblock && typeof this.state.unblock === "function") {
            this.state.unblock();
        }

        if (process.env.NODE_ENV !== "development") {
            window.removeEventListener("beforeunload", onUnload);
        }
        this.props.clearClusterState();
        this.props.unlockClusters(this.props.clusterList);
    }

    initCommonModules = (modulePrincList, clusterList = null) => {
        let modulePrincListArray = getArrayFromObj(modulePrincList)

        const clusterMap = new Map();

        // Inizializza la mappa
        if (clusterList) {
            clusterList?.forEach(cluster => {
                const clusterId = cluster.clusterId;
                clusterMap.set(clusterId, new Set());
            })
        } else {
            this.state.clusterList?.forEach(cluster => {
                const clusterId = cluster.clusterId;
                clusterMap.set(clusterId, new Set());
            })
        }

        // Popola la mappa con i dati
        modulePrincListArray.forEach((module) => {
            const {clusterId} = module;
            clusterMap.get(clusterId).add(module);
        });

        // Trova i moduli comuni a tutti i cluster
        const commonModules = [...clusterMap.values()].reduce((commonSet, currentSet) => {
            if (commonSet === null) {
                return new Set(currentSet);
            }
            return new Set([...commonSet].filter(module => {
                const {moduleName} = module
                const moduleNamesInCurrentSet = [...currentSet].map(module => module.moduleName)
                return moduleNamesInCurrentSet.includes(moduleName)
            }));
        }, null);

        if (!commonModules) return []

        return [...commonModules]
    }

    addClassification = (classificationIdList, clusterId) => {
        let clusterListCopy = cloneDeep(this.state.clusterList)
        clusterListCopy = clusterListCopy.map(cluster => {
            if (cluster.clusterId === clusterId) {
                cluster.classifications = classificationIdList
            }
            return cluster
        })
        this.setState({
            clusterList: clusterListCopy
        })
        this.setClassificationEdited(true)
    }

    togglePopup = (stateName) =>
        this.setState((prevState) => {
            return {[stateName]: !prevState[stateName]};
        });

    redirectToHome = () => {
        this.props.history.push("/");
    };

    getCluster = (cluster) => {
        this.props.getCluster(cluster);
    };

    /*** SEARCH CLUSTER ***/
    handleSearch = (value) => {
        if (this.state.isClusterChanged) {
            this.togglePopup("isPopupChangeOpen");
            this.setState({selectedCluster: value});
        } else {
            this.getCluster(value);
            this.setState({isClusterChanged: false});
        }
    };

    handleDescriptionChange = (opt, clusterId) => {
        const newValue = opt.target.value;
        const descriptions = cloneDeep(this.state.descriptions)
        descriptions.filter(d => d.clusterId === clusterId).forEach(d => {
            const oldDescription = this.state.clusterList.find(cl => cl.clusterId === clusterId)?.clusterDescription;
            d.value = newValue;
            d.isChanged = oldDescription !== newValue;
            d.editMode = true
        })
        this.setState({
            descriptions
        })
    }

    toogleDescriptionEditMode = (clusterId) => {
        const descriptions = cloneDeep(this.state.descriptions)
        descriptions.filter(d => d.clusterId === clusterId).forEach(c => c.editMode = true);
        this.setState({
            descriptions
        })
    }

    /* DRAG DROP */

    dragStart = (type, draggedModule) => {
        this.setState({
            dragFrom: type,
            draggedModule,
        });
    };

    //Every time you do an action
    //Se clusterId = null, voglio aggiungere il modulo a tutti i cluster aperti
    dropModule = (type, brandCode = null, clusterId = null) => {
        let modulePrincList = cloneDeep(this.state.modulePrincList),//{ ...this.state.modulePrincList },
            draggedModule = cloneDeep(this.state.draggedModule),
            checkPrinc = [],
            checkRemoved = [];

        //check if module is in princ
        //Se clusterId = null, devo controllare in tutti i cluster
        if (modulePrincList[draggedModule.macroFamily] !== undefined) {
            checkPrinc = modulePrincList[draggedModule.macroFamily].filter((m) => {
                if (clusterId) {
                    return m.moduleName === draggedModule.moduleName && m.clusterId === clusterId
                } else {
                    return m.moduleName === draggedModule.moduleName
                }
            });
        }

        //check if module is in removed
        //Se clusterId = null, devo controllare in tutti i cluster
        if (this.state.moduleRemovedList.length) {
            checkRemoved = this.state.moduleRemovedList.filter((m) => {
                if (clusterId) {
                    return m.moduleName === draggedModule.moduleName && m.clusterId === clusterId
                } else {
                    return m.moduleName === draggedModule.moduleName
                }
            });
        }

        const alreadyPresent = checkPrinc.length

        if (type === "Princ") {
            if (alreadyPresent) {
                this.togglePopup("isPopupPresentOpen");
            } else {
                if (checkRemoved.length > 0) {
                    this.restoreItem(draggedModule);
                }
                this.insertInPrinc(modulePrincList, draggedModule, clusterId, brandCode);
                //check if module added is wip
                // if (draggedModule.wip) counterWip++;
            }
        } else if (type === "All") {
            if (checkRemoved.length > 0) {
                this.restoreItem(draggedModule);
            }
            const commonModules = cloneDeep(this.state.commonModules);
            this.insertInPrinc(modulePrincList, draggedModule, clusterId, brandCode, false, true);
            this.insertInCommon(commonModules, draggedModule);
        }
        this.dragStop();

        this.setState({
            isClusterChanged: true,
            // counterWip,
        });
    };

    dragStop = () => {
        this.setState({
            dragFrom: null,
        });
    };

    insertBackFromRemoved = (moduleItem, clusterId, brandCode) => {
        let modulePrincListCopy = cloneDeep(this.state.modulePrincList);
        this.restoreItem(moduleItem, clusterId);
        this.insertInPrinc(modulePrincListCopy, moduleItem, clusterId, brandCode, true, false);
    };

    insertInAllFromRemoved = (moduleItem) => {
        let commonModulesCopy = cloneDeep(this.state.commonModules);
        this.restoreItemFromCommonModules(moduleItem)
        // eslint-disable-next-line no-undef
        this.insertInCommon(commonModulesCopy, moduleItem);
    };

    //Se clusterId=null, devo aggiungere il modulo a tutti i cluster
    insertInPrinc = (modulePrincList, moduleToAdd, clusterId, brandCode, insertBack = false, allow = false) => {
        // moduleToAdd.boType = "NOT SWITCH";
        if (this.state.dragFrom !== "princ" || insertBack || allow) {
            if (this.state.dragFrom === "search")
                moduleToAdd.clusterCompositionStatus = "ADDED";
            if (moduleToAdd.clusterCompositionStatus === "REMOVED")
                moduleToAdd.clusterCompositionStatus = "ADDED";

            if (modulePrincList[moduleToAdd.macroFamily] === undefined) {
                modulePrincList[moduleToAdd.macroFamily] = [];
                modulePrincList = sortMacro(modulePrincList);
            }

            let moduleList = modulePrincList[moduleToAdd.macroFamily];
            let modulesToAddWithClusterId = []

            // qui non faccio il controllo se è presente o no perché non lo sto trascinando in all -> salta fuori il popup nel caso sia già presente
            if (clusterId) {
                if (moduleToAdd.brand === brandCode) {
                    modulesToAddWithClusterId.push({
                        ...moduleToAdd,
                        clusterId
                    })
                }
            } else {
                // lo button in all
                this.state.clusterList.forEach(cluster => {
                    // verifico non sia già presente in uno dei cluster aperti. Se è così lo escludo
                    const alreadyPresent = moduleList.filter(module => module.moduleName === moduleToAdd.moduleName && module.clusterId === cluster.clusterId).length
                    if (moduleToAdd.brand === cluster.brandCode && !alreadyPresent) {
                        modulesToAddWithClusterId.push({
                            ...moduleToAdd,
                            clusterId: cluster.clusterId
                        })
                    }
                })
            }

            moduleList.push(...modulesToAddWithClusterId);
            modulePrincList[moduleToAdd.macroFamily] = orderModules(moduleList);
            this.getPercOfRefreshClusters(modulePrincList);
            this.getRangeFacingClusters(modulePrincList);

            this.setState({
                modulePrincList,
                commonModules: orderModulesByMacro(this.initCommonModules(modulePrincList)),
            });
        }
    };

    insertInCommon = (commonModulesList, moduleToAdd) => {
        if (this.state.dragFrom === "search" || this.state.dragFrom === "princ")
            moduleToAdd.clusterCompositionStatus = "ADDED";
        if (moduleToAdd.clusterCompositionStatus === "REMOVED")
            moduleToAdd.clusterCompositionStatus = "ADDED";

        if (commonModulesList[moduleToAdd.macroFamily] === undefined) {
            commonModulesList[moduleToAdd.macroFamily] = [];
            commonModulesList = sortMacro(commonModulesList);
        }

        let moduleList = commonModulesList[moduleToAdd.macroFamily];
        let modulesToAddWithClusterId = []

        this.state.clusterList.forEach(cluster => {
            if (moduleToAdd.brand === cluster.brandCode) {
                modulesToAddWithClusterId.push({
                    ...moduleToAdd,
                    clusterId: cluster.clusterId
                })
            }
        })

        moduleList.push(...modulesToAddWithClusterId);
        commonModulesList[moduleToAdd.macroFamily] = orderModules(uniqBy(moduleList, "moduleName"));
        this.getPercOfRefreshClusters(commonModulesList);
        this.getRangeFacingClusters(commonModulesList);

        this.setState({
            commonModules: commonModulesList
            //commonModules: orderModulesByMacro(this.initCommonModules(modulePrincList)),
        })
    }

    /*
    retrieveModulesInCommon = () => {
       const allModules = []

       Object.keys(this.state.modulePrincList)?.forEach(macroFamily => {
          allModules.push(...this.state.modulePrincList[macroFamily])
       })

       console.log(allModules)

       const allClusterIds = this.state.clusterList.map(cluster => cluster.clusterId)
       let allModulesByClusterId = []
       allClusterIds.forEach(clusterId => {
          const modulesInClusterId = allModules.filter(module => module.clusterId === clusterId)
          allModulesByClusterId.push(modulesInClusterId)
       })

       let commonModules = intersectionBy(...allModulesByClusterId, "moduleName")
       console.log(allModulesByClusterId)
       console.log(commonModules)

       const result = {}
       commonModules.forEach(module => {
          if(!result[module.macroFamily]){
             result[module.macroFamily] = [module]
          }
          else{
             result[module.macroFamily].push(module)
          }
       })

       console.log(result)

       return result
    }
    */
    removeFromPrinc = (moduleToRemove, clusterId) => {
        let modulePrincList = cloneDeep(this.state.modulePrincList),
            moduleRemovedList = cloneDeep(this.state.moduleRemovedList),
            moduleToRemoveToUpdate = cloneDeep(moduleToRemove);

        //remove from princ
        modulePrincList[moduleToRemove.macroFamily] = modulePrincList[moduleToRemove.macroFamily].filter((m) => {
            if (clusterId) {
                if (m.moduleName === moduleToRemove.moduleName && m.clusterId === clusterId) return false
                return true
            }
            return m.moduleName !== moduleToRemove.moduleName
        });

        moduleToRemoveToUpdate.clusterCompositionStatus = "REMOVED";
        moduleRemovedList.push(moduleToRemoveToUpdate);

        // se non ho più moduli per quella famiglia, rimuovo l'entry nell'oggetto
        if (modulePrincList[moduleToRemove.macroFamily].length === 0) {
            delete modulePrincList[moduleToRemove.macroFamily]
        }

        this.getPercOfRefreshClusters(modulePrincList);
        this.getRangeFacingClusters(modulePrincList);
        this.setState({
            modulePrincList,
            moduleRemovedList,
            isClusterChanged: true,
            commonModules: orderModulesByMacro(this.initCommonModules(modulePrincList)),
        });
    };

    removeFromAll = (moduleToRemove) => {
        let commonModulesCopy = cloneDeep(this.state.commonModules),
            removedCommonModulesCopy = cloneDeep(this.state.removedCommonModules),
            moduleRemovedListCopy = cloneDeep(this.state.moduleRemovedList),
            moduleToRemoveToUpdate = cloneDeep(moduleToRemove);

        //remove from "all" section
        commonModulesCopy[moduleToRemove.macroFamily] = commonModulesCopy[moduleToRemove.macroFamily].filter((m) => {
            return m.moduleName !== moduleToRemove.moduleName
        });

        moduleToRemoveToUpdate.clusterCompositionStatus = "REMOVED";
        removedCommonModulesCopy.push(moduleToRemoveToUpdate);

        // se non ho più moduli per quella famiglia, rimuovo l'entry nell'oggetto
        if (commonModulesCopy[moduleToRemove.macroFamily].length === 0) {
            delete commonModulesCopy[moduleToRemove.macroFamily]
        }

        this.state.modulePrincList[moduleToRemove.macroFamily].forEach(module => {
            if (module.moduleName === moduleToRemove.moduleName) {
                module.clusterCompositionStatus = "REMOVED"
                moduleRemovedListCopy.push(module);
            }
        })

        this.removeFromPrinc(moduleToRemove)

        this.setState({
            commonModules: commonModulesCopy,
            removedCommonModules: removedCommonModulesCopy,
            moduleRemovedList: moduleRemovedListCopy
        });
    }

    restoreItem = (moduleToRemove, clusterId) => {
        let name = moduleToRemove.moduleName,
            moduleRemovedList = _.cloneDeep(this.state.moduleRemovedList);

        moduleRemovedList = moduleRemovedList.filter(m => {
            if (clusterId) {
                if (m.moduleName === name && m.clusterId === clusterId) return false
                return true
            }
            return m.moduleName !== name
        });

        this.setState({
            moduleRemovedList,
            isClusterChanged: true
        });
    };

    restoreItemFromCommonModules = (moduleToRestore) => {
        let name = moduleToRestore.moduleName,
            removedCommonModulesCopy = _.cloneDeep(this.state.removedCommonModules),
            moduleRemovedList = _.cloneDeep(this.state.moduleRemovedList),
            modulePrincList = cloneDeep(this.state.modulePrincList)


        removedCommonModulesCopy = removedCommonModulesCopy.filter(m => {
            return m.moduleName !== name
        });

        // se lo riaggiungo, devo riaggiungerlo anche ai cluster in cui si trovava
        // e rimuoverlo dalle loro liste di moduli eliminati
        let moduleRemovedListNew = _.cloneDeep(moduleRemovedList)
        moduleRemovedList.forEach(module => {
            if (module.moduleName === moduleToRestore.moduleName) {
                module.clusterCompositionStatus = "ADDED"
                if (!modulePrincList[moduleToRestore.macroFamily]) {
                    modulePrincList[moduleToRestore.macroFamily] = []
                }
                modulePrincList[moduleToRestore.macroFamily].push(module)
                moduleRemovedListNew = moduleRemovedListNew.filter(m => m.moduleName !== module.moduleName)
            }
        })

        this.setState({
            removedCommonModules: removedCommonModulesCopy,
            modulePrincList,
            moduleRemovedList: moduleRemovedListNew,
            isClusterChanged: true,
        });
    }

    handleClickModule = (moduleClicked) => {
        window.open('/module?moduleName=' + moduleClicked.moduleName + '&monthId=' + moduleClicked.monthId);
    };

    goToSingleModule = (moduleItem = null) => {
        if (moduleItem === null || moduleItem.moduleName === undefined)
            moduleItem = this.state.moduleClicked;
        this.props.getWorkingModuleWithLock([moduleItem]);
    };

    /* SAVE AND PUBLISH */
    /*
    handleSaveClick = () => {
       let cluster = { ...this.state.cluster };
       if (!cluster.userLock) {
          cluster.userLock = this.props.username;
       }
       let modPrinc = { ...this.state.modulePrincList };
       let modRemoved = [...this.state.moduleRemovedList];
       this.props.saveCluster(cluster, modPrinc, modRemoved);
    };
    */

    // save all clusters in list
    handleSaveClick = (changeDescription) => {
        let clusterList = cloneDeep(this.state.clusterList)
        clusterList = clusterList.map(cluster => {
            if (!cluster.userLock) {
                cluster.userLock = this.props.username;
            }
            if (changeDescription) {
                this.state.descriptions
                    .filter(d => d.isChanged)
                    .forEach(description => {
                        const clusterId = description.clusterId;
                        const cluster = clusterList.find(c => c.clusterId === clusterId);
                        cluster.clusterDescription = description.value;
                    })
            }
            return cluster
        })
        let modPrinc = cloneDeep(this.state.modulePrincList);
        let modRemoved = cloneDeep(this.state.moduleRemovedList);
        let onlyDescription = !this.state.isClusterChanged && changeDescription;
        this.props.saveClusterList(clusterList, modPrinc, modRemoved, onlyDescription);
        this.setState({
            isPopupSaveNewDescriptionOpen: false
        })
    };

    checkBeforeSaving = () => {
        if (this.state.descriptions.some(d => d.isChanged)) {
            this.setState({
                isPopupSaveNewDescriptionOpen: true
            })
        } else this.handleSaveClick();
    }

    closeSavePopup = () => {
        this.setState({
            isPopupSavedOpen: false,
            isClusterChanged: false,
            isPopupSaveNewDescriptionOpen: false
        });
        this.props.isNotSaved();
    };

    handlePublishClick = () => {
        if (this.state.counterWip > 0) {
            this.setState({isPopupWipOpen: true});
        } else {
            this.setState({isPopupWantPublishOpen: true});
        }
    };

    /*
    checkPublishCluster = () => {
       let cluster = { ...this.state.cluster };
       let modPrinc = { ...this.state.modulePrincList };
       let modRemoved = [...this.state.moduleRemovedList];

       if (!cluster.userLock) {
          cluster.userLock = this.props.username;
       }

       this.props.checkForPublish(cluster, modPrinc, modRemoved, (response) => {
          if (response.length === 0) {
             this.props.publishCluster(cluster, modPrinc, modRemoved);
             this.setState({
                isPopupWantPublishOpen: false,
             });
          } else {
             this.setState({
                isPopupClusterTwin: true,
                isPopupWantPublishOpen: false,
                clustersIdentical: response,
             });
          }
       });
    };
    */

    checkPublishCluster = () => {
        let clusterList = cloneDeep(this.state.clusterList)
        let modulePrincList = cloneDeep(this.state.modulePrincList)
        let moduleRemovedList = cloneDeep(this.state.moduleRemovedList)

        clusterList = clusterList.map(cluster => {
            if (!cluster.userLock) {
                cluster.userLock = this.props.username;
            }
            return cluster
        })

        this.props.checkForPublish(clusterList, modulePrincList, moduleRemovedList, (response) => {
            // check there are no duplicates for each cluster
            const duplicates = []
            const clusterDuplicatedResponseMap = response.clusterDuplicatedResponseMap
            Object.keys(clusterDuplicatedResponseMap).forEach(clusterId => {
                duplicates.push(...clusterDuplicatedResponseMap[clusterId])
            })

            if (duplicates.length === 0) {
                this.props.publishCluster(clusterList, modulePrincList, moduleRemovedList)
                this.setState({
                    isPopupWantPublishOpen: false,
                });
            } else {
                this.setState({
                    isPopupClusterTwin: true,
                    isPopupWantPublishOpen: false,
                    clustersIdentical: duplicates,
                });
            }
        })
    }

    /*
    publishCluster = () => {
       let cluster = { ...this.state.cluster };
       let modPrinc = { ...this.state.modulePrincList };
       let modRemoved = [...this.state.moduleRemovedList];

       if (!cluster.userLock) {
          cluster.userLock = this.props.username;
       }

       this.props.publishCluster(cluster, modPrinc, modRemoved);
       this.setState({
          isPopupClusterTwin: false,
          clustersIdentical: [],
       });
    };
    */

    publishCluster = () => {
        let clusterList = cloneDeep(this.state.clusterList)
        let modulePrincList = cloneDeep(this.state.modulePrincList)
        let moduleRemovedList = cloneDeep(this.state.moduleRemovedList)

        clusterList = clusterList.map(cluster => {
            if (!cluster.userLock) {
                cluster.userLock = this.props.username;
            }
            return cluster
        })

        this.props.publishCluster(clusterList, modulePrincList, moduleRemovedList);
        this.setState({
            isPopupClusterTwin: false,
            clustersIdentical: [],
        });
    };

    /*
    getPercOfRefresh = (modulePrincList) => {
       let cluster = { ...this.props.cluster };
       cluster.modules = getArrayFromObj(modulePrincList);
       this.props.getPercOfRefresh(cluster);
    };
    */

    getPercOfRefreshClusters = (modulePrincList) => {
        let clusterList = cloneDeep(this.props.clusterList)
        clusterList = clusterList.map(cluster => {
            const clusterModules = getArrayFromObj(modulePrincList).filter(module => module.clusterId === cluster.clusterId)
            cluster.modules = clusterModules
            return cluster
        })

        this.props.getPercOfRefreshList(clusterList);
    };

    /*
    getRangeFacing = (modulePrincList) => {
       let cluster = { ...this.props.cluster };
       cluster.modules = getArrayFromObj(modulePrincList);

       this.props.getRangeFacing(cluster);
    };
    */

    getRangeFacingClusters = (modulePrincList) => {
        let clusterList = cloneDeep(this.props.clusterList)
        clusterList = clusterList.map(cluster => {
            const clusterModules = getArrayFromObj(modulePrincList).filter(module => module.clusterId === cluster.clusterId)
            cluster.modules = clusterModules
            return cluster
        })

        this.props.getRangeFacingClusters(clusterList)
    }

    setLiaListEdited = (isLiaListEdited) => {
        this.setState({isLiaListEdited});
    };

    setClassificationEdited = (isClassificationEdited) => {
        this.setState({isClassificationEdited});
    };

    setIsModuleSelected = () => {
        this.setState({isModuleSelected: !this.state.isModuleSelected,});
    };

    handleClusterLias = (liasList, clusterId) => {
        let clusterListCopy = cloneDeep(this.state.clusterList)
        clusterListCopy = clusterListCopy.map(cluster => {
            if (cluster.clusterId === clusterId) {
                cluster.lias = liasList
            }
            return cluster
        })
        this.setState({
            clusterList: clusterListCopy
        })
    }

    handleScroll = (e) => {
        // scrollHeight -> altezza del contenuto, compreso quello non visibile
        // clientHeight -> altezza di un elemento in pixels -> height + padding - margins
        const {scrollTop, scrollHeight} = e.target;
        const numOfOpenedClusters = this.state.clusterList?.length ? this.state.clusterList?.length : 1
        const elementHeight = Math.floor(scrollHeight / numOfOpenedClusters)

        const index = Math.floor(scrollTop / elementHeight)

        this.setState({
            visibleClusterIndex: index,
        });
    };

    render() {
        let redirect = null;

        // check editable element of the page should be disabled
        const isClusterDisabled = (cluster) => {
            return (
                cluster?.status === "SUBMIT_IN_PROGRESS" || //se cluster.status === SUBMIT_IN_PROGRESS
                (cluster?.status === "SUBMITTED" &&
                    this.props.auth &&
                    this.props.auth?.grants &&
                    !canReopen(
                        this.props.auth?.grants,
                        cluster?.brandCode,
                        !this.props.isNewCluster ? cluster?.country : false
                    ) // se cluster.status = SUBMITTED e !canReopen
                ) ||
                (cluster ?
                    cluster?.locked &&
                    cluster?.userLock &&
                    cluster?.userLock.toUpperCase() !== this.props.username?.toUpperCase()
                    : false)
            )
        }

        const allClustersNotEditable = () => {
            let areDisabled = true;
            this.state.clusterList?.forEach(cluster => {
                areDisabled = areDisabled &&
                    (this.props.auth.grants &&
                        !isVisibleForUser(
                            this.props.auth.grants,
                            false, // guarda edit, non submit
                            cluster.brandCode,
                            cluster.country // è la starzone
                        )
                    );
            })
            return areDisabled;
        }

        let disableAll = false
        this.state.clusterList?.forEach(cluster => {
            const disable = isClusterDisabled(cluster)
            disableAll = disableAll || disable
        })

        if (this.props.isNewCluster) {
            disableAll = false;
        }

        let atLeastOneIsWip = () => {
            return this.state.clusterList?.some(cluster => cluster.status === "WIP");
        }
        return (
            <React.Fragment>
                {redirect}
                {this.state.clusterList?.length > 0 ? (
                    <div className="cluster-comp">
                        <Header
                            auth={this.props.auth}
                            data={this.state.clusterList}
                            type="cluster"
                            isClusterChanged={this.state.isClusterChanged || this.state.descriptions.some(d => d.isChanged)}
                            isDescriptionChanged={this.state.descriptions.some(d => d.isChanged)}
                            isDisabled={disableAll} // da sistemare con il valore corretto.
                            isSubmitDisabled={disableAll} // se anche solo uno non è abilitato, il submit è disabilitato
                            handleSaveClick={this.checkBeforeSaving}
                            handlePublishClick={this.handlePublishClick}
                            isLoadingSave={this.props.isLoadingSave}
                            isLoadingPublish={this.props.isLoadingPublish}
                            isLiaListEdited={this.state.isLiaListEdited}
                            isClassificationEdited={this.state.isClassificationEdited}
                            newTabHeader={this.state.newTabHeader}
                            selectedFilters={this.props.selectedFilters}
                            isWip={atLeastOneIsWip()}
                        />
                        <div className="area-container col-12">
                            <div style={{
                                height: "100vh",
                                width: "50%"
                            }}>
                                <div
                                    className="col-6 cluster-container"
                                    style={{
                                        height: "60%",
                                        maxWidth: "none"
                                    }}
                                    onScroll={this.handleScroll}
                                >
                                    {
                                        this.state.clusterList?.map((cluster, index) => {
                                            const percObj = this.state.percRefreshList?.clusterDtoList.filter(clusterWPerc => clusterWPerc.clusterId === cluster.clusterId)[0]
                                            const rangeFacing = this.state.rangeFacingList?.filter(rangeFacing => rangeFacing.clusterId === cluster.clusterId)[0].rangeFacing
                                            const clusterRemovedModuleList = this.state.moduleRemovedList?.filter(module => module.clusterId === cluster.clusterId)
                                            const isDisabled =
                                                disableAll ||
                                                (this.props.auth.grants &&
                                                    !isVisibleForUser(
                                                        this.props.auth.grants,
                                                        false, // guarda edit, non submit
                                                        cluster.brandCode,
                                                        cluster.country // è la starzone
                                                    )
                                                );

                                            // filter modulePrincList per clusterId
                                            const modulePrincListCopy = cloneDeep(this.state.modulePrincList)
                                            Object.keys(modulePrincListCopy).forEach(key => {
                                                modulePrincListCopy[key] = modulePrincListCopy[key].filter(module => module.clusterId === cluster.clusterId)
                                                if (modulePrincListCopy[key].length === 0) {
                                                    delete modulePrincListCopy[key]
                                                }
                                            })

                                            const description = this.state.descriptions.find(d => d.clusterId === cluster.clusterId) || this.state.descriptions[0];

                                            return (
                                                <ModuleListAreaContainer
                                                    // isAlternativeOpen={this.state.isAlternativeOpen}
                                                    stickHeader={this.state.visibleClusterIndex === index}
                                                    key={cluster.clusterId}
                                                    title={cluster.clusterLabel}
                                                    description={description}
                                                    status={cluster.status}
                                                    listModule={modulePrincListCopy}
                                                    listRemoved={clusterRemovedModuleList} // moduleRemovedList diventa {cluster1: [...], cluster2: [...] ...}

                                                    dragStart={(moduleItem) =>
                                                        this.dragStart("princ", moduleItem)
                                                    }
                                                    dragStop={this.dragStop}
                                                    dragFrom={this.state.dragFrom}
                                                    dropModule={() => this.dropModule("Princ", cluster.brandCode, cluster.clusterId)}
                                                    addToClusterComp={this.insertBackFromRemoved}
                                                    remove={this.removeFromPrinc}
                                                    isDisabled={isDisabled}

                                                    goToSingleModule={this.handleClickModule}
                                                    cluster={cluster}
                                                    percObj={percObj} // mi serve da BE
                                                    rangeFacing={rangeFacing}
                                                    setLiaListEdited={this.setLiaListEdited}
                                                    addClassification={this.addClassification}
                                                    setClusterLias={this.handleClusterLias}
                                                    handleDescriptionChange={$event => this.handleDescriptionChange($event, cluster.clusterId)}
                                                    toogleDescriptionEditMode={() => this.toogleDescriptionEditMode(cluster.clusterId)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <ModuleAll
                                    dragStart={(moduleItem) =>
                                        this.dragStart("all", moduleItem)
                                    }
                                    dragStop={this.dragStop}
                                    dropModule={() => this.dropModule("All")}
                                    modules={this.state.commonModules}
                                    removedModules={this.state.removedCommonModules}
                                    removeFromAll={this.removeFromAll}
                                    insertInAllFromRemoved={this.insertInAllFromRemoved}
                                    isDisabled={allClustersNotEditable()}
                                />
                            </div>
                            <SearchModule
                                dragStart={(moduleItem) =>
                                    this.dragStart("search", moduleItem)
                                }
                                dragEnd={this.dragStop}
                                isDisabled={disableAll}
                                goToSingleModule={this.handleClickModule}
                                counterWip={this.state.counterWip}
                                listModule={[...this.state.percRefreshList?.clusterDtoList ?
                                    this.state.percRefreshList.clusterDtoList.map(cluster => cluster.modules).reduce((modA, modB) => [...modA, ...modB])
                                    : [],
                                    ...this.state.moduleRemovedList
                                ]}
                                commonModules={this.state.commonModules} // così sa quali sono i moduli già presenti in ogni cluster
                            />
                        </div>
                        {this.state.isPopupSavedOpen && (
                            <Popup
                                popupClass="popup-small"
                                close={this.closeSavePopup}
                                title={
                                    this.props.action === "save"
                                        ? "Cluster correctly saved"
                                        : "The submission process has been started"
                                }
                                handleClick={this.closeSavePopup}
                            />
                        )}

                        {this.state.isPopupSaveNewDescriptionOpen && (
                            <Popup
                                popupClass="popup-small"
                                close={() => this.togglePopup('isPopupSaveNewDescriptionOpen')}
                                title="Save cluster"
                                isDoubleButton
                                btnName2="No"
                                btnName="Yes"
                                handleClick={() => this.handleSaveClick(true)}
                                handleClickSecondary={() => this.handleSaveClick(false)}
                            >
                                Are you sure you want also to change the cluster description?
                            </Popup>
                        )}

                        {this.state.isPopupWipOpen && (
                            <Popup
                                popupClass="popup-small"
                                close={() => this.togglePopup("isPopupWipOpen")}
                                btnName="Close"
                                handleClick={() => this.togglePopup("isPopupWipOpen")}
                                title="Attention! Module in WIP"
                            >
                                It is not possible to publish the cluster, because there
                                are modules in{" "}
                                <span className="bold">work in progress</span>.
                                <br/>
                                Please publish all modules WIP before publishing the
                                entire cluster.
                            </Popup>
                        )}
                        <div className="wrapper-confirm-popup">
                            {this.state.isPopupWantPublishOpen ? (
                                this.props.isCheckingPublish ? (
                                    <Popup
                                        popupClass="popup-small "
                                        btnClass="btn-lia btn-small"
                                    >
                                        <Loader/>
                                    </Popup>
                                ) : (
                                    <Popup
                                        popupClass="popup-lia"
                                        btnClass="btn-lia btn-small"
                                        isDoubleButton
                                        btnName2="Cancel"
                                        btnName="Confirm"
                                        title="Are you sure you want to publish this form on the following Lia ?"
                                        isFlex
                                        close={() =>
                                            this.togglePopup("isPopupWantPublishOpen")
                                        }
                                        handleClick={this.checkPublishCluster}
                                    >
                                        <LiaList/>
                                    </Popup>
                                )
                            ) : null}
                        </div>

                        {this.state.isPopupExitOpen && (
                            <Popup
                                popupClass="popup-small"
                                close={() => this.togglePopup("isPopupExitOpen")}
                                title=" Do you want to exit the page?"
                                isDoubleButton
                                btnName2="No"
                                btnName="Yes"
                                isFlex
                                handleClick={this.goToSingleModule}
                            >
                                All unsaved changes will be lost.
                            </Popup>
                        )}
                        {this.state.isPopupChangeOpen && (
                            <Popup
                                popupClass="popup-small"
                                isFlex
                                close={() => this.togglePopup("isPopupChangeOpen")}
                                title=" Do you want to change the cluster?"
                                btnName="Confirm"
                                handleClick={() => {
                                    this.getCluster(this.state.selectedCluster);
                                    this.setState({isClusterChanged: false});
                                    this.togglePopup("isPopupChangeOpen");
                                }}
                            >
                                All unsaved changes will be lost.
                            </Popup>
                        )}
                        {this.state.isPopupPresentOpen && (
                            <Popup
                                popupClass="popup-small"
                                close={() => this.togglePopup("isPopupPresentOpen")}
                                btnName="Close"
                                isFlex
                                handleClick={() =>
                                    this.togglePopup("isPopupPresentOpen")
                                }
                            >
                                The module is already present
                            </Popup>
                        )}
                        {this.state.isPopupClusterTwin && (
                            <Popup
                                popupClass="popup-small"
                                close={() => this.togglePopup("isPopupClusterTwin")}
                                isDoubleButton
                                btnName2="Do not publish"
                                btnName="Publish the clusters anyway"
                                handleClick={this.publishCluster}
                                isFlex
                                title="There are these clusters with the same module composition:"
                            >
                                {this.state.clustersIdentical.map((cluster) => (
                                    <p key={cluster}>{cluster}</p>
                                ))}
                            </Popup>
                        )}
                        {this.state.isPopupUnlockedClusterOpen && (
                            <Popup
                                close={() =>
                                    this.togglePopup("isPopupUnlockedClusterOpen")
                                }
                                title=""
                                popupClass="popup-small"
                            >
                                <p className="like-title">Sorry!</p>
                                <p className="like-title">
                                    The cluster was unlocked by another user, you can’t
                                    save or publish.
                                </p>
                            </Popup>
                        )}
                    </div>
                ) : (
                    <div className="full-page">
                        <Loader/>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        action: state.cluster.action,
        isNewCluster: state.cluster.isNewCluster,
        cluster: state.cluster.cluster,
        clusterList: state.cluster.clusterList,
        results: state.filter.results,
        isActionSuccessful: state.cluster.isActionSuccessful,
        percRefresh: state.cluster.percRefresh,
        percRefreshList: state.cluster.percRefreshList,
        isReleaseClosed: state.release.isReleaseClosed,
        isCheckingPublish: state.cluster.isCheckingPublish,
        lockClusterStatus: state.user.lockCluster,
        username: state.authentication.user.user_name,
        rangeFacing: state.cluster.rangeFacing, // vecchio, da eliminare
        rangeFacingList: state.cluster.rangeFacingList,
        lockModule: state.user.lockModule,
        isLoadingSave: state.cluster.isLoadingSave,
        isLoadingPublish: state.cluster.isLoadingPublish,
        errorCheckPublish: state.cluster.errorCheckPublish,
        errorSave: state.cluster.errorSave,
        auth: state.authentication,
        selectedFilters: state.filter.selectedFilters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInitialFiltersList: () => dispatch(actions.getInitialFiltersList()),
        getCluster: (param) => dispatch(actions.getCluster(param)),
        getFilteredList: (type, params) =>
            dispatch(actions.getFilteredList(type, params)),
        //getRangeFacing: (modules) => dispatch(actions.getRangeFacing(modules)),
        getRangeFacingClusters: (modules) => dispatch(actions.getRangeFacingClusters(modules)),
        saveCluster: (cluster, princ, rem) =>
            dispatch(actions.saveCluster(cluster, princ, rem)),
        saveClusterList: (clusterList, princ, rem, onlyDescription) =>
            dispatch(actions.saveClusterList(clusterList, princ, rem, onlyDescription)),
        checkForPublish: (cluster, princ, rem, callback) =>
            dispatch(actions.checkForPublish(cluster, princ, rem, callback)),
        publishCluster: (cluster, modPrinc, modRemoved) =>
            dispatch(actions.publishCluster(cluster, modPrinc, modRemoved)),
        isNotSaved: () => dispatch(actions.isNotSaved()),
        clearClusterState: () => dispatch(actions.clearClusterState()),
        //getPercOfRefresh: (ass) => dispatch(actions.getPercOfRefreshCluster(ass)),
        getPercOfRefreshList: (ass) => dispatch(actions.getPercOfRefreshClusterList(ass)),
        lockCluster: (cluster) => dispatch(actions.lockCluster(cluster)),
        unlockClusters: (clusterList) => dispatch(actions.unlockClusters(clusterList)),
        unlockCluster: (cluster) => dispatch(actions.unlockCluster(cluster)),
        getWorkingModuleWithLock: (selectedModules) =>
            dispatch(actions.getWorkingModuleWithLock(selectedModules)),
        getClusterWithLock: (cluster, callback, viewer) =>
            dispatch(actions.getClusterWithLock(cluster, callback, viewer)),
        getModuleFilters: (filters, noformat) =>
            dispatch(actions.getModuleFilters(filters, noformat)),
        getClusterListSuccess: (clusters, callId, callback) =>
            dispatch(actions.getClusterListSuccess(clusters, callId, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterComposition);
