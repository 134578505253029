import React, {Component} from "react";
import * as _ from "lodash";
import {connect} from "react-redux";
import {getCookie} from "../../../services/cookie";
import Popup from "../../UI-components/Popup";
import CustomSelect from "../../UI-components/CustomSelect";
import CustomInput from "../../UI-components/CustomInput";
import * as actions from "../../../store/actions";
import CustomIcon from "../../UI-components/CustomIcon";
import Loader from "../../UI-components/Loader";
import {CLUSTER_EXISTS} from "../../../config";
import {
    grantEditableBrands,
    isAdmin,
    intersectArray,
    formatOptionsArray,
} from "../../../services/Api";
import axios from "axios";

class AddClusterPopup extends Component {
    state = {
        planningChannel: [],
        brand: null,
        collection: null,
        country: null,
        stores: [],
        segment: null,
        stars: null,
        storeId: null,
        clusterId: "",
        clusterLabel: "",
        clusterDescription: "",
        options: {
            customerSegmentation: [],
            starsCustomer: [],
            rangeFacing: [],
            storeId: [],
            codiceStoreSap: [],
        },
        isBrandWithRule: false,
        selectedLego: 0,
        lia: [],
        starZones: [],
        checkIfExist: false,
        isLoading: false,
        errorMessage: "",
    };

    componentDidMount = () => {
        if (this.props.options) {
            this.setState({
                options: _.cloneDeep(this.props.options),
            });
        }

        (this.props.allLia && !!this.props.allLia.length) ||
        this.props.getAllLiaList();

        if (this.props.selectedFilters.brand.length === 1) {
            this.setState({
                brand: this.props.selectedFilters.brand[0],
            });
        }

        if (this.props.selectedFilters.commodities.length === 1) {
            this.setState({
                collection: this.props.selectedFilters.commodities[0],
            });
        }

        if (this.activeBrands.length === 1) {
            this.setState({brand: this.activeBrands[0]});
        }

        this.props.getInitialFiltersList();
    };

    checkIfExist = () => {
        axios({
            url: CLUSTER_EXISTS,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            data: {
                clusterId: this.state.clusterId.trim(),
                monthId: this.props.selectedFilters.months.value,
                clusterLabel: this.state.clusterLabel,
            },
        })
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
                if (!(this.state.clusterDescription?.length > 0))
                    this.setState({
                        clusterDescription: response.data.description,
                    })
                this.handleCreate();
                return response;
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    checkIfExist: true,
                    errorMessage: error?.response?.data?.error ?? ""
                });
                console.error(error);
            });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.options !== this.props.options) {
            this.setState({
                options: _.cloneDeep(this.props.options),
            });
        }
    };

    addStore = () => {
        let stores = [...this.state.stores];
        let newStore = {
            codiceStoreSap: this.props.options.codiceStoreSap[0],
            customerSegmentation: [this.state.segment.value],
            starsCustomer: this.state.stars.value,
            storeId: this.state.storeId.value,
        };

        stores.push(newStore);

        this.setState({
            stores,
        });
    };

    removeStore = (i) => {
        let stores = [...this.state.stores];
        stores.splice(i, 1);

        this.setState({
            stores,
        });
    };

    handleChange = (selectType, optionsSelected) => {
        switch (selectType) {
            case "country":
                this.setState({
                    country: optionsSelected,
                    stores: [],
                    storeId: [],
                });
                break;
            case "starZones":
                this.setState({
                    starZones: optionsSelected,
                });
                break;
            case "segment":
                this.setState({segment: optionsSelected});
                break;
            case "stars":
                this.setState({
                    stars: optionsSelected,
                    storeId: null,
                    options: {
                        ...this.state.options,
                        storeId: [],
                    },
                });

                const selectedFilters = {
                    brand: this.props.selectedFilters.brand,
                    commodities: this.props.selectedFilters.commodities,
                    countryList: this.props.selectedFilters.countryList,
                    months: this.props.selectedFilters.months,
                    planningChannel: this.props.selectedFilters.planningChannel,
                };

                this.props.getStoreIdFiltered(
                    selectedFilters,
                    [optionsSelected.value]
                    // this.state.segment ? [this.state.segment.value] : []
                );
                break;
            case "storeId":
                this.setState({
                    storeId: optionsSelected,
                });
                this.props.getCodiceStoreSap(this.props.selectedFilters, [
                    optionsSelected.value,
                ]);
                break;
            case "clusterId":
                this.setState({
                    clusterId: optionsSelected.currentTarget.value,
                });
                break;
            case "clusterLabel":
                this.setState({
                    clusterLabel: optionsSelected.currentTarget.value,
                });
                break;
            case "clusterDescription":
                this.setState({
                    clusterDescription: optionsSelected.currentTarget.value,
                });
                break;
            default:
        }
    };

    handleCreate = () => {
        let valuesFilters = {
            stores: this.state.stores,
        };

        valuesFilters.clusterId = this.state.clusterId;
        valuesFilters.clusterLabel = this.state.clusterLabel;
        valuesFilters.clusterDescription = this.state.clusterDescription;

        const selectedFilters = {};

        selectedFilters.collection = this.state.collection;
        selectedFilters.brand = this.state.brand;
        selectedFilters.months = this.props.selectedFilters.months;
        selectedFilters.lias = this.state.lia;
        selectedFilters.starZones = this.state.starZones.map((el) => {
            return el.value;
        });

        this.props.setNewCluster(selectedFilters, valuesFilters);
        this.props.redirect();
    };

    handleChangePC = (option) => {
        this.setState({
            planningChannel: option,
        });
    };

    handleChangeBrand = (option) => {
        this.setState({
            brand: option,
        });
    };

    handleChangeCollection = (option) => {
        this.setState({
            collection: option,
        });
    };

    handleChangeLia = (option) => {
        this.setState({
            lia: option,
        });
    };

    hasSpecialCharacter(str) {
        const regex = /[.]{3,}|[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~]/;
        return regex.test(str);
    }

    isEnabledSubmit = () => {
        const isCollectionSelected = this.state.collection;
        const isBrandSelected = this.state.brand;
        const checkDisabled = this.state.clusterId;
        const disabledLabel = this.state.clusterLabel;
        const isLiaSelected = this.state.lia.length;
        const isStarZoneSelected = this.state.starZones.length;
        const isClusterIdCorrect = this.state.clusterId.trim().includes(" ");
        const isClusterIdSpecialCharacter = this.hasSpecialCharacter(this.state.clusterId);
        const isClusterLabelSpecialCharacter = this.hasSpecialCharacter(this.state.clusterLabel);

        return (
            isCollectionSelected &&
            isBrandSelected &&
            isLiaSelected &&
            checkDisabled &&
            disabledLabel &&
            isStarZoneSelected &&
            !isClusterIdCorrect &&
            !isClusterIdSpecialCharacter &&
            !isClusterLabelSpecialCharacter

        );
    };

    formatLia = (lias) => {
        return lias.map((lia) => ({
            value: lia.liaCode,
            label: lia.liaCode,
            planningChannelId: lia.planningChannelId,
        }));
    };

    checkAdd = () => {
        return (
            this.state.stars &&
            this.state.storeId &&
            this.state.segment &&
            this.props.isGetCodiceStoreSapEnded
        );
    };

    activeBrands = formatOptionsArray(
        intersectArray(
            this.props.selectedFilters.brand.map((brand) => brand.value),
            grantEditableBrands(this.props.grants).map((brand) => brand.value)
        )
    );

    render() {
        const {months} = this.props.selectedFilters;
        const options = this.state.options;
        let isClusterIdCorrect = this.state.clusterId.trim().includes(" ");
        let isClusterIdSpecialCharacter = this.hasSpecialCharacter(this.state.clusterId);
        let isClusterLabelSpecialCharacter = this.hasSpecialCharacter(this.state.clusterLabel);


        const activeBrands = this.activeBrands;

        const familyStarOptionsSelectedFromHome = this.props.selectedFilters
            .countryList.length
            ? this.props.selectedFilters.countryList
            : null;

        const entireListCountryListFromHome =
            this.props.filterHome && this.props.filterHome.countries;
        console.log("this.state.errorMessage", this.state.errorMessage);
        return (
            <Popup
                popupClass="overflow-visible create-cluster-popup popup-large"
                close={this.props.togglePopup}
                disabled={!this.isEnabledSubmit()}
                title="Create a new cluster"
                btnName="Create"
                handleClick={() => {
                    this.setState({
                        isLoading: true,
                    });
                    this.checkIfExist();
                }}
            >
                {this.state.isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="row no-gutters section">
                            <div className="section-content">
                                <div className="col-12">
                                    <p className="mb-2 ml-1">
                                        <span className="bold">Month:</span>{" "}
                                        {months.label.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters section">
                            <div className="section-content">
                                <div className="col-md-5">
                                    <div className="input-container">
                                        {this.state.isBrandWithRule ? null : (
                                            <CustomInput
                                                placeholder="Cluster Label"
                                                inputClass="input-big clusterId"
                                                handleChange={(o) =>
                                                    this.handleChange("clusterLabel", o)
                                                }
                                                value={this.state.clusterLabel}
                                                maxLength="40"
                                            />
                                        )}
                                    </div>
                                    <div className="input-container">
                                        {this.state.isBrandWithRule ? null : (
                                            <CustomInput
                                                placeholder="Cluster Description"
                                                inputClass="input-big clusterId"
                                                handleChange={(o) =>
                                                    this.handleChange("clusterDescription", o)
                                                }
                                                value={this.state.clusterDescription}
                                                maxLength="100"
                                            />
                                        )}
                                    </div>

                                    <div className="lia-select">
                                        {this.props.allLia ? (
                                            <CustomSelect
                                                isMulti
                                                placeholder="Lia"
                                                options={this.formatLia(this.props.allLia)}
                                                value={this.state.lia}
                                                handleChange={this.handleChangeLia}
                                            />
                                        ) : null}
                                    </div>
                                    {this.props.selectedFilters.commodities.length ===
                                    1 ? (
                                        <CustomSelect
                                            placeholder="Collection"
                                            options={this.props.selectedFilters.commodities}
                                            value={this.props.selectedFilters.commodities}
                                            className="mt-1"
                                            disabled
                                        />
                                    ) : (
                                        <CustomSelect
                                            placeholder="Collection"
                                            options={this.props.filterHome.commodities}
                                            value={this.state.collection}
                                            handleChange={this.handleChangeCollection}
                                            className="mt-1"
                                        />
                                    )}
                                </div>

                                <div className="col-7">
                                    <div className="input-container">
                                        {this.state.isBrandWithRule ? null : (
                                            <CustomInput
                                                placeholder="Cluster Id"
                                                inputClass="input-big clusterId"
                                                handleChange={(o) =>
                                                    this.handleChange("clusterId", o)
                                                }
                                                value={this.state.clusterId}
                                                maxLength="20"
                                            />
                                        )}
                                        <div className="error">
                                            {isClusterIdCorrect && (
                                                <p>
                                                    Attention! You can't add a space!
                                                </p>
                                            )}
                                            {isClusterIdSpecialCharacter && (
                                                <p>
                                                    Attention! You can't use special characters!
                                                </p>
                                            )}
                                            {isClusterLabelSpecialCharacter && (
                                                <p>
                                                    Attention! You can't use special characters!
                                                </p>
                                            )}
                                        </div>

                                    </div>
                                    {this.props.selectedFilters.brand &&
                                    this.props.selectedFilters.brand.length ? (
                                        <CustomSelect
                                            placeholder="Brand"
                                            options={activeBrands}
                                            value={
                                                activeBrands.length === 1
                                                    ? activeBrands
                                                    : this.state.brand
                                            }
                                            disabled={activeBrands.length === 1}
                                            handleChange={this.handleChangeBrand}
                                        />
                                    ) : (
                                        <CustomSelect
                                            placeholder="Brand"
                                            options={
                                                isAdmin(this.props.grants)
                                                    ? this.props.filterHome.brand
                                                    : grantEditableBrands(this.props.grants)
                                            }
                                            value={this.state.brand}
                                            handleChange={this.handleChangeBrand}
                                        />
                                    )}
                                    <div style={{marginTop: "0.5rem"}}>
                                        <CustomSelect
                                            className="add-select-family"
                                            placeholder="Stars Zone"
                                            options={
                                                !familyStarOptionsSelectedFromHome
                                                    ? entireListCountryListFromHome
                                                    : familyStarOptionsSelectedFromHome
                                            }
                                            handleChange={(o) =>
                                                this.handleChange("starZones", o)
                                            }
                                            value={this.state.starZones}
                                            isMulti
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section select-container">
                            <div className="col-12">
                                <h4>Add a store:</h4>
                                <div className="add-store">
                                    <CustomSelect
                                        placeholder="Customer Segmentation"
                                        options={options.customerSegmentation}
                                        handleChange={(o) =>
                                            this.handleChange("segment", o)
                                        }
                                        value={this.state.segment}
                                    />
                                    <CustomSelect
                                        placeholder="Stars Customers"
                                        options={options.starsCustomer}
                                        handleChange={(o) =>
                                            this.handleChange("stars", o)
                                        }
                                        value={this.state.stars}
                                    />
                                    <CustomSelect
                                        placeholder="Store"
                                        disabled={!this.state.stars}
                                        options={options.storeId}
                                        handleChange={(o) =>
                                            this.handleChange("storeId", o)
                                        }
                                        value={this.state.storeId}
                                        isLoading={
                                            options.storeId.length === 0 ? true : false
                                        }
                                    />
                                    <CustomIcon
                                        iconClass="icon-plus-round"
                                        disabled={!this.checkAdd()}
                                        handleClick={this.addStore.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <h4>Store List:</h4>
                                <div className="remove-store-container">
                                    {!!this.state.stores.length &&
                                        this.state.stores.map((store, i) => (
                                            <div className="remove-store" key={store + i}>
                                                <p>
                                                    <span>{store.customerSegmentation}</span>{" "}
                                                    -<span>{store.starsCustomer}</span> -
                                                    <span>{store.storeId}</span>
                                                </p>
                                                <CustomIcon
                                                    iconClass="icon-minus-round"
                                                    handleClick={() => this.removeStore(i)}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {this.state.checkIfExist && (
                    <Popup
                        popupClass="overflow-visible create-cluster-popup error-message-container"
                        close={() => {
                            this.setState({
                                checkIfExist: false,
                            });
                        }}
                        title=""
                        btnName="Ok"
                        handleClick={() => {
                            this.setState({
                                checkIfExist: false,
                            });
                        }}
                    >
                        {this.state.errorMessage.includes(";") ? (
                            this.state.errorMessage.split(";").map((error, i) => {
                                return (
                                    <p
                                        style={{textAlign: "initial"}}
                                        key={i.toString()}
                                    >
                                        {error}
                                    </p>
                                );
                            })
                        ) : (
                            <p>{this.state.errorMessage}</p>
                        )}
                        {this.state.errorMessage === "" ||
                        this.state.errorMessage === null ||
                        this.state.errorMessage === undefined ||
                        this.state.errorMessage === [] ||
                        !this.state.errorMessage ? (
                            <p>
                                Unable to create the cluster because it already exists.
                            </p>
                        ) : null}
                    </Popup>
                )}
            </Popup>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedFilters: state.filter.selectedFilters,
        options: state.filter.filtersOptionsCluster,
        countryList: state.filter.filterHome.countryList,
        optionsByStars: state.filter.optionsByStars,
        isGetCodiceStoreSapEnded: state.filter.isGetCodiceStoreSapEnded,
        filterHome: state.filter.filterHome,
        allLia: state.cluster.lia,
        grants: state.authentication.grants,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInitialFiltersList: () => dispatch(actions.getInitialFiltersList()), //HomePage Filter List
        setNewCluster: (filters, cluster) =>
            dispatch(actions.setNewCluster(filters, cluster)),
        getStoreIdFiltered: (selectedFilters, stars, customer) =>
            dispatch(actions.getStoreIdFiltered(selectedFilters, stars, customer)),
        getCodiceStoreSap: (filters, storeSap) =>
            dispatch(actions.getCodiceStoreSap(filters, storeSap)),
        getClusterName: (cluster, callback) =>
            dispatch(actions.getClusterName(cluster, callback)),
        getAllLiaList: () => dispatch(actions.getAllLiaList()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClusterPopup);
