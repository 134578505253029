import React, {Component} from "react";
import * as _ from "lodash";
import {connect} from "react-redux";
import {getCookie} from "../../../services/cookie";
import * as actions from "../../../store/actions/";
import Popup from "../../UI-components/Popup";
import CustomSelect from "../../UI-components/CustomSelect";
import CustomInput from "../../UI-components/CustomInput";
import Loader from "../../UI-components/Loader";
import {MODULE_EXISTS} from "../../../config";
import {
    grantEditableBrands,
    isAdmin,
    intersectArray,
    formatOptionsArray,
} from "../../../services/Api";
import axios from "axios";

class AddModulePopup extends Component {
    state = {
        moduleNew: [
            {label: "Carry Over", value: false},
            {label: "Newness", value: true},
        ],
        macroFamily: [],
        moduleNameSwitch: null,
        isBestseller: false,
        length: "",
        moduleName: "",
        moduleLabel: "",
        moduleDescription: null,
        isErrorNegativeLength: false,
        planningChannel: [],
        brand: null,
        starZones: [],
        commodities: null,
        initialSize: 0,
        checkIfExist: false,
        isLoading: false,
        errorMessage: "",
    };

    componentDidMount = () => {
        if (this.props.selectedFilters.brand.length === 1) {
            this.setState({
                brand: this.props.selectedFilters.brand[0],
            });
        }
        if (this.activeBrands.length === 1) {
            this.setState({brand: this.activeBrands[0]});
        }
        const selectedCollection = this.props.selectedFilters.commodities;
        if (selectedCollection && selectedCollection.length === 1) {
            this.setState({commodities: selectedCollection[0]});
        }
        this.props.getInitialFiltersList();
    };

    checkIfExist = () => {
        axios({
            url: MODULE_EXISTS,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            data: {
                moduleName: this.state.moduleName.trim(),
                monthId: this.props.selectedFilters.months.value,
                moduleLabel: this.state.moduleLabel,
                moduleDescription: this.state.moduleDescription,
            },
        })
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
                if (!(this.state.moduleDescription?.length > 0))
                    this.setState({
                        moduleDescription: response.data.description,
                    })
                this.handleCreate();
                return response;
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    checkIfExist: true,
                    errorMessage: error?.response?.data?.error ?? ""
                });
                console.error(error);
            });
    };

    handleChange = (type, opts) => {
        if (type === "macroFamily") {
            if (opts.label === "bestseller")
                return this.setState({
                    [type]: opts,
                    isBestseller: true,
                });
            else
                return this.setState({
                    [type]: opts,
                    isBestseller: false,
                });
        }

        if (
            type === "initialSize" ||
            type === "moduleLabel"
        ) {
            opts = opts.target.value;
        }

        if (type === "moduleName") {
            opts = opts.target.value;
        }
        if (type === "moduleDescription") {
            opts = opts.target.value;
        }

        this.setState({
            [type]: opts,
        });
    };

    handleCreate = () => {
        if (this.state.length < 0) {
            return this.setState({
                isErrorNegativeLength: true,
            });
        }

        const brand = this.state.brand.value;
        const collection = this.state.commodities.value;
        const macroFamily = this.state.macroFamily.value;

        const module = {
            macroFamily,
            collection,
            brand,
            length: 0,
            initialSize: this.state.initialSize,
            moduleName: this.state.moduleName,
            moduleLabel: this.state.moduleLabel,
            moduleDescription: this.state.moduleDescription,
            productDtoList: [],
            monthId: this.props.selectedFilters.months.value,
            starZones:
                this.state.starZones.length &&
                this.state.starZones.map((el) => {
                    return el.value;
                }),
        };

        const objToSend = [
            {
                macroFamily,
                moduleList: [module],
            },
        ];

        // module creation action
        this.props.setNewModule(objToSend);

        if (isAdmin(this.props.grants) || this.props.isNewMacro) {
            this.props.redirect();
        } else {
            delete module.initialSize;

            this.props.saveModule(module, true);
            this.props.sendHomeFilters(this.props.selectedFilters);
        }
    };

    handleChangePC = (option) => {
        this.setState({
            planningChannel: option,
        });
    };

    handleChangeBrand = (option) => {
        this.setState({
            brand: option,
        });
    };

    handleChangeCollection = (option) => {
        this.setState({
            commodities: option,
        });
    };

    hasSpecialCharacter(str) {
        const regex = /[.]{3,}|[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~]/;
        return regex.test(str);
    }

    isEnabledSubmit = () => {
        let isCollectionSelected = this.state.commodities;
        let isBrandSelected = this.state.brand;
        let isStarsZonesSelected = this.state.starZones.length;
        let isMacroFamilySelected = this.state.macroFamily.constructor === Object;
        let isModuleNameCorrect = this.state.moduleName.trim().includes(" ");
        let isModuleNameSpecialCharacter = this.hasSpecialCharacter(this.state.moduleName);
        let isModuleLabelSpecialCharacter = this.hasSpecialCharacter(this.state.moduleLabel);
        return (
            isMacroFamilySelected &&
            isCollectionSelected &&
            isStarsZonesSelected &&
            isBrandSelected &&
            this.state.moduleName !== "" &&
            this.state.moduleLabel !== "" &&
            !isModuleNameCorrect &&
            !isModuleNameSpecialCharacter &&
            !isModuleLabelSpecialCharacter
        );
    };

    activeBrands = formatOptionsArray(
        intersectArray(
            this.props.selectedFilters.brand.map((brand) => brand.value),
            grantEditableBrands(this.props.grants).map((brand) => brand.value)
        )
    );

    render() {
        let macroList = this.props.macroList;

        if (this.props.isNewMacro) {
            macroList = [
                ...this.props.macroList,
                {label: this.props.newMacro, value: this.props.newMacro},
            ];
            macroList = _.orderBy(macroList, ["value"]);
        }

        let newMacroFamily = this.props.macroFamilyList;
        if (this.props.isNewMacro) {
            newMacroFamily = [
                {label: this.props.newMacro, value: this.props.newMacro},
            ];
        }

        let isModuleNameCorrect = this.state.moduleName.trim().includes(" ");
        let isModuleNameSpecialCharacter = this.hasSpecialCharacter(this.state.moduleName);
        let isModuleLabelSpecialCharacter = this.hasSpecialCharacter(this.state.moduleLabel);

        const activeBrands = this.activeBrands;

        const familyStarOptionsSelectedFromHome = this.props.selectedFilters
            .countryList.length
            ? this.props.selectedFilters.countryList
            : null;

        const entireListCountryListFromHome =
            this.props.filterHome && this.props.filterHome.countries;

        const commodities = this.props.selectedFilters.commodities && this.props.selectedFilters.commodities.length > 0 ?
            this.props.selectedFilters.commodities
            :
            this.props.filterHome.commodities;
        console.log("this.state.errorMessage", this.state.errorMessage);
        return (
            <Popup
                popupClass="overflow-visible create-module popup-large"
                close={() => this.props.togglePopup("isAddModuleOpen")}
                title="Create a new module"
                btnName={!this.state.isLoading ? "Create" : null}
                disabled={!this.isEnabledSubmit()}
                handleClick={() => {
                    this.checkIfExist();

                    this.setState({
                        isLoading: true,
                    });
                }}
            >
                {this.state.isLoading ? (
                    <Loader/>
                ) : (
                    <div className="row no-gutters section">
                        <div className="section-content">
                            <div className="col-5 select-container">
                                <p>
                                    <span className="bold">Month: </span>
                                    {this.props.months.label.toUpperCase()}
                                </p>
                                {this.props.selectedFilters.brand &&
                                this.props.selectedFilters.brand.length ? (
                                    <CustomSelect
                                        placeholder="Brand"
                                        options={activeBrands}
                                        value={
                                            activeBrands.length === 1
                                                ? activeBrands
                                                : this.state.brand
                                        }
                                        disabled={activeBrands.length === 1}
                                        handleChange={this.handleChangeBrand}
                                    />
                                ) : (
                                    <CustomSelect
                                        placeholder="Brand"
                                        options={
                                            isAdmin(this.props.grants)
                                                ? this.props.filterHome.brand
                                                : grantEditableBrands(this.props.grants)
                                        }
                                        value={this.state.brand}
                                        handleChange={this.handleChangeBrand}
                                    />
                                )}
                                <CustomInput
                                    inputClass="input-big"
                                    placeholder="Module Label"
                                    maxLength="40"
                                    handleChange={(e) =>
                                        this.handleChange("moduleLabel", e)
                                    }
                                    value={this.state.moduleLabel}
                                />
                                <CustomInput
                                    inputClass="input-big"
                                    placeholder="Module Description"
                                    maxLength="100"
                                    handleChange={(e) =>
                                        this.handleChange("moduleDescription", e)
                                    }
                                    value={this.state.moduleDescription}
                                />
                                {this.props.moduleList ? (
                                    <CustomSelect
                                        className="add-select-family"
                                        placeholder="Family"
                                        options={macroList ? macroList : []}
                                        handleChange={(o) =>
                                            this.handleChange("macroFamily", o)
                                        }
                                        value={this.state.macroFamily}
                                    />
                                ) : (
                                    <CustomSelect
                                        className="add-select-family"
                                        placeholder="Family"
                                        options={newMacroFamily}
                                        handleChange={(o) =>
                                            this.handleChange("macroFamily", o)
                                        }
                                        value={this.state.macroFamily}
                                    />
                                )}
                                <CustomSelect
                                    className="add-select-family"
                                    placeholder="Stars Zone"
                                    options={
                                        !familyStarOptionsSelectedFromHome
                                            ? entireListCountryListFromHome
                                            : familyStarOptionsSelectedFromHome
                                    }
                                    handleChange={(o) =>
                                        this.handleChange("starZones", o)
                                    }
                                    value={this.state.starZones}
                                    isMulti
                                />
                            </div>
                            <div className="col-5 error-container">
                                <CustomSelect
                                    placeholder="Collection"
                                    options={commodities}
                                    value={this.state.commodities}
                                    disabled={commodities.length === 1}
                                    handleChange={this.handleChangeCollection}
                                />

                                <CustomInput
                                    inputClass="input-big"
                                    placeholder="Module Name"
                                    maxLength="24"
                                    handleChange={(e) =>
                                        this.handleChange("moduleName", e)
                                    }
                                    value={this.state.moduleName}
                                />
                                <CustomInput
                                    inputClass={
                                        "input-big" +
                                        (this.state.isErrorNegativeLength
                                            ? " input-error"
                                            : "")
                                    }
                                    placeholder="Initial Size"
                                    min="0"
                                    type="number"
                                    handleChange={(e) =>
                                        this.handleChange("initialSize", e)
                                    }
                                    value={this.state.initialSize}
                                />
                                <div className="error">
                                    {isModuleNameCorrect && (
                                        <p>
                                            Attention! You can't add a space!
                                        </p>
                                    )}
                                    {isModuleNameSpecialCharacter && (
                                        <p>
                                            Attention! You can't use special characters!
                                        </p>
                                    )}
                                    {isModuleLabelSpecialCharacter && (
                                        <p>
                                            Attention! You can't use special characters!
                                        </p>
                                    )}
                                    {this.state.isErrorNegativeLength ? (
                                        <p>
                                            Attention! You can't write a negative length!
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.checkIfExist && (
                    <Popup
                        popupClass="overflow-visible create-module error-message-container"
                        close={() => {
                            this.setState({
                                checkIfExist: false,
                            });
                        }}
                        title=""
                        btnName="Ok"
                        handleClick={() => {
                            this.setState({
                                checkIfExist: false,
                            });
                        }}
                    >
                        {!(this.state.errorMessage === "" ||
                            this.state.errorMessage === null ||
                            this.state.errorMessage === undefined ||
                            this.state.errorMessage === []) && this.state.errorMessage.includes(";") ? (
                            this.state.errorMessage.split(";").map((error, i) => {
                                return (
                                    <p
                                        style={{textAlign: "initial"}}
                                        key={i.toString()}
                                    >
                                        {error}
                                    </p>
                                );
                            })
                        ) : (
                            <p>{this.state.errorMessage}</p>
                        )}
                        {this.state.errorMessage === "" ||
                        this.state.errorMessage === null ||
                        this.state.errorMessage === undefined ||
                        this.state.errorMessage === [] ||
                        !this.state.errorMessage ? (
                            <p>
                                Unable to create the module because it already exists.
                            </p>
                        ) : null}
                    </Popup>
                )}
            </Popup>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedFilters: state.filter.selectedFilters,
        filterHome: state.filter.filterHome,
        macroList:
            state.module.homeFilters && state.module.homeFilters.macroFamily,
        macroFamilyList: state.module.macroList,
        grants: state.authentication.grants,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInitialFiltersList: () => dispatch(actions.getInitialFiltersList()), //HomePage Filter List
        saveModule: (mod, type) => dispatch(actions.saveModule(mod, type)),
        sendHomeFilters: (selectedFilters) =>
            dispatch(actions.sendHomeFilters(selectedFilters)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddModulePopup);
