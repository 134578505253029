import * as actions from "../../store/actions";

import React, { Component } from "react";
import { formatNumber, printLabelSku } from "../../services/Api";

import CustomIcon from "../UI-components/CustomIcon";
import Popup from "../UI-components/Popup";
import ReactTooltip from "react-tooltip";
import Rotation from "react-rotation";
import { connect } from "react-redux";
import {IMAGE_BASE_URL} from "../../config";

class Sku extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isZoomOpen: false,
         isFaded: false,
         is360: true,
      };

      this.myRef = React.createRef();
   }

   componentDidMount() {
      this.scrollHightlightSku();
      const releaseYear = this.props.sku?.releaseDate.split(" ")[0]
      if(releaseYear >= "2023"){
         this.setState({ is360: false })
      }
   }

   componentDidUpdate() {

      this.scrollHightlightSku();
   }

   scrollHightlightSku() {
      let {
         isActiveSku,
         isWorking,
         isHighlighted,
         scrollHighlightWASku,
         scrollHighlightActiveSku,
      } = this.props;

      if (scrollHighlightActiveSku && isActiveSku && isHighlighted) {
         this.myRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
         });
         this.props.cleanScrollHighlightActive();
      } else if (scrollHighlightWASku && isWorking && isHighlighted) {
         this.myRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
         });
         this.props.cleanScrollHighlightWA();
      }
   }

   toggleZoom = () => {
      this.setState((prevState) => {
         return { isZoomOpen: !prevState.isZoomOpen };
      });
      if (this.props.draggable) {
         this.props.changeDraging();
      }

   }

   prevStatus() {
      if (!this.props.sku.previousStatus) {
         return false;
      }
      const currentStatus = this.props.sku.mustHave ? "MH" : "EL";
      return currentStatus !== this.props.sku.previousStatus;
   }

   getUrl = (model) => {
      let color = model.color;
      let itemModel = model.model;

      if (color?.includes("/")) {
         color = color.replace("/", "_");
      }
      itemModel = itemModel?.replace(" ", "_");
      //360 images
      return IMAGE_BASE_URL + `/${model.brand}/${itemModel}/${itemModel}__${color}`;
   };

   getUrlOneImage = (model) => {
      let color = model.color;
      let itemModel = model.model;
      if (color?.includes("/")) {
         color = color.replace("/", "_");
      }
      itemModel = itemModel?.replace(" ", "_");
      //one image
      return `https://myluxottica-im.luxottica.com/${model.brand}/${itemModel}/${itemModel}__${color}.jpg?impolicy=EB_Resize&imwidth=640`;
   };


   render() {
      let {
         sku,
         isActiveSku,
         openPopup,
         isWorking,
         draggable,
         onDragStart,
         handleClick,
         isEditable,
         searchType,
         isHighlighted,
         orderSku,
         handleEligibleAndMH,
         moduleCompositionStatus = "",
         showNewInBadge = "",
         handleClickOnZoom,
         // tB:03-2022
         showMHELasLabel = false,
         className,

      } = this.props;


      let isFaded = this.props.isFaded && isActiveSku && !isHighlighted;

      const doorIndex = sku && sku.doorIndex;

      const doorBorder =
         doorIndex <= 30 ? 'redBorder' :
            doorIndex > 30 && doorIndex <= 50 ? 'orangeBorder' :
               doorIndex > 50 && doorIndex <= 70 ? 'greyBorder' :
                  doorIndex > 70 && doorIndex <= 90 ? 'greenBorder' :
                     doorIndex > 90 ? 'blueBorder' : '';

      function addZeroes(num) {
         return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2})
      }

      return (
         <>
            <div
               id={sku.material + sku.color + sku.size}
               className={
                  `glass-box sku-card component ${doorBorder}` +
                  (isFaded ? " faded" : "") +
                  (isHighlighted ? " highlighted" : "")
                  + (className && className.length ? ' ' + className : '')
               }
               // draggable={draggable}
               onDragStart={onDragStart}
               ref={this.myRef}
            >
               {sku.flagN && (
                  <div className="new-label-container">
                     <span className="new-label">NEW</span>
                  </div>
               )}
               <div className="card-image">
                  {sku.deleted && (
                     <CustomIcon iconClass={"icon-triangle-black"} />
                  )}
                  {searchType === "sku" && (
                     <>
                        {/* Commented to hide label "added" */}
                        {/* {sku.from && !isActiveSku && (
                           <div className="label-sku gold">{sku.from}</div>
                        )} */}
                        <span className="info-label" onClick={openPopup}>
                           i
                        </span>
                        {/* tB:03-2022 */}
                        {((typeof moduleCompositionStatus === "string" &&
                           moduleCompositionStatus === "REMOVED") ||
                           showMHELasLabel) &&
                           sku.mustHave && (
                              <span className="el-mh--label">MH</span>
                           )}
                        {/* tB:03-2022 */}
                        {((typeof moduleCompositionStatus === "string" &&
                           moduleCompositionStatus === "REMOVED") ||
                           showMHELasLabel) &&
                           !sku.mustHave && (
                              <span className="el-mh--label">EL</span>
                           )}

                        {handleEligibleAndMH &&
                           isEditable &&
                           typeof moduleCompositionStatus === "string" &&
                           moduleCompositionStatus !== "REMOVED" && (
                              <CustomIcon
                                 iconClass={`icon-el${sku.mustHave ? "" : " icon-el-active"
                                    }${this.prevStatus() ? " prev-status" : ""}`}
                                 doubleClick={handleEligibleAndMH}
                              />
                           )}
                        {isWorking &&
                           isEditable &&
                           typeof moduleCompositionStatus === "string" &&
                           moduleCompositionStatus !== "REMOVED" && (
                              <CustomIcon
                                 iconClass="icon-cestino"
                                 handleClick={handleClick}
                              />
                           )}
                        {sku.sapStatus &&
                           sku.sapStatus.toUpperCase() === "Z" && (
                              <span className="disabled-z" />
                           )}
                        {sku.sapStatus &&
                           sku.sapStatus.toUpperCase() === "I" && (
                              <span className="disabled-i" />
                           )}
                     </>
                  )}
                  <div
                     className={
                        "img-product" + (draggable ? " cursor-grab" : "")
                     }
                  >
                     <img
                        className="img-fluid img-rounded"
                        src={sku.imageUrl}
                        alt={sku.model + sku.color + sku.size}
                        onClick={() =>
                           this.props.handleClickOnImg(this.props.sku)
                        }
                     />

                     <CustomIcon
                        iconClass="icon-zoom"
                        iconContClass="icon-zoom-container"
                        handleClick={
                           typeof handleClickOnZoom === "function" &&
                              handleClickOnZoom != null
                              ? () => handleClickOnZoom()
                              : this.toggleZoom
                        }
                     />
                     {searchType === "sku" && (
                        <div className="flag-container">
                           {sku.flagNewAdv && (
                              <div className="new-adv-bullet" />
                           )}

                           {sku.flagDisco && (
                              <span
                                 className={
                                    /*sku.globalCatalogue && sku.globalCatalogue === "Restrict"
                        ? "gc gc-res"
                        :*/ "disc" + (isWorking ? " working" : "")
                                    /* Commented to hide label added */
                                    // + (sku.from ? " added" : "")
                                 }
                              />
                           )}
                           {<div className="globalCatalogue">

                              {sku.globalCatalogue &&
                                 sku.globalCatalogue === "Restrict" && (
                                    <span
                                       className={
                                          "gc gc-res" +
                                          (isWorking ? " working" : "")
                                          /* Commented to hide label added */
                                          // + (sku.from ? " added" : "")
                                       }
                                    />
                                 )}

                              {sku.globalCatalogue &&
                                 sku.globalCatalogue === "YES" && (
                                    <span className="gc" />
                                 )}

                              {sku.globalCatalogue &&
                                 sku.globalCatalogue !== "Restrict" &&
                                 sku.globalCatalogue !== "NO" &&
                                 sku.globalCatalogue !== "YES" && (
                                    <span
                                       className="gc gc-other"
                                       title={sku.globalCatalogue}
                                    />
                                 )}
                           </div>}
                           {sku && sku.polar === "Yes" && (
                              <span className="polar" />
                           )}
                        </div>
                     )}
                     {<div className="rank-container">
                        {sku.rankSellOut !== undefined && !isActiveSku && (
                           <span className="rank-label">
                              R = {sku.rankSellOut ? sku.rankSellOut : 0}
                           </span>
                        )}
                        {isActiveSku && orderSku && (
                           <span className="rank-label">
                              R ={" "}
                              {orderSku === "sellIn" ? sku.rankSellIn : null}R ={" "}
                              {orderSku === "sellIn" ? sku.rankSellIn : null}{" "}
                              {orderSku === "outOfStars"
                                 ? sku.rankOutOfStars
                                 : null}
                              {orderSku === "sellOut" ? sku.rankSellOut : null}
                           </span>
                        )}
                        {showNewInBadge &&
                           typeof showNewInBadge === "string" &&
                           showNewInBadge !== "" &&
                           showNewInBadge !== null &&
                           showNewInBadge !== undefined && (
                              <>
                                 <span
                                    data-tip={showNewInBadge}
                                    className="new-in-badge"
                                 >
                                    NEW IN
                                 </span>
                                 <ReactTooltip
                                    className="disco-remove-table__tooltip--style"
                                    effect="solid"
                                 />
                              </>
                           )}
                     </div>}
                  </div>
               </div>
               <div className="card-label">
                  <div className="label-container">
                     <div className="name-product">
                        {printLabelSku(searchType, sku)}
                     </div>
                     <div className="performance-info">
                        <span>
                           S.O. = {sku.sellOut ? formatNumber(sku.sellOut) : 0},
                        </span>
                        {isActiveSku && (
                           <>
                              <br />
                              <span>
                                 S.I. ={" "}
                                 {sku.sellIn ? formatNumber(sku.sellIn) : 0},
                              </span>
                           </>
                        )}
                        <p>
                           Sku's turn = {addZeroes(sku.skuTurn)}
                        </p>
                        {sku.previousVersion ? null : (
                           <span className="icon-star" />
                        )}
                     </div>
                  </div>
               </div>
            </div>
            {this.state.isZoomOpen && (
               <Popup
                  close={this.toggleZoom}
                  popupClass="popup-zoom"
                  handleClick={this.toggleZoom}
               >
                  {this.state.is360 ? (
                     <Rotation cycle>
                        <img src={this.getUrl(sku) + "_000A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_030A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_060A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_090A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_120A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_150A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_180A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_210A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_240A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_270A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_300A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "_330A.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "__P21__shad__fr.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                        <img src={this.getUrl(sku) + "__P20__shad__fr.png?impolicy=EB_Resize&wid=320"} alt={sku.model} />
                     </Rotation>
                  ) : (
                      <Rotation cycle>
                         <img src={this.getUrl(sku) + "__P21__shad__qt.png?impolicy=EB_Resize&wid=320"} alt={sku.model}/>
                         <img src={this.getUrl(sku) + "__P21__shad__fr.png?impolicy=EB_Resize&wid=320"} alt={sku.model}/>
                         <img src={this.getUrl(sku) + "__P21__shad__lt.png?impolicy=EB_Resize&wid=320"} alt={sku.model}/>
                         <img src={this.getUrl(sku) + "__P21__shad__cfr.png?impolicy=EB_Resize&wid=320"} alt={sku.model}/>
                         <img src={this.getUrl(sku) + "__P21__shad__bk.png?impolicy=EB_Resize&wid=320"} alt={sku.model}/>
                      </Rotation>
                  )
                  }
                  <p>
                     {sku.model} {sku.color} {sku.size}
                  </p>
               </Popup>)
            }
         </>
      );
   }
}

const mapStateToProps = (state) => {
   return {};
};

const mapDispatchToProps = (dispatch) => {
   return {
      cleanScrollHighlightActive: () =>
         dispatch(actions.cleanScrollHighlightActive()),
      cleanScrollHighlightWA: () => dispatch(actions.cleanScrollHighlightWA()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sku);
